import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { interceptorProviders } from './interceptors/interceptors'
import { Logger } from './services/logger.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { serviceProvider } from './services/services';
import { LocalStorageService } from 'ngx-webstorage';
import { AppLoaderComponent } from './services/app-loader/app-loader.component';
import { SharedMaterialModule } from 'app/shared/shared-material.module';


@NgModule({
  imports: [
    CommonModule,
    SharedMaterialModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: Logger, useFactory: Logger },
    interceptorProviders, 
    serviceProvider,
    LocalStorageService
  ],
  declarations: [ AppLoaderComponent ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}
