import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedComponentsModule } from "./components/shared-components.module";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { SharedDirectivesModule } from "./directives/shared-directives.module";
import { SharedMaterialModule } from "./shared-material.module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    SharedComponentsModule,
    SharedMaterialModule,
    SharedPipesModule,
    SharedDirectivesModule,
  ],
})
export class SharedModule {}
