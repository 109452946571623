import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreviousRouteService {

    private history: string[] = [];

    constructor(
      private router: Router
    ) {}
  
    public loadRouting() {
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe(({urlAfterRedirects}: NavigationEnd) => {
           this.history = [...this.history, urlAfterRedirects];
        });
        this.history
    }
  

    public SetUrls(): void{
      this.router.events.subscribe((event) => {
        if(event instanceof NavigationEnd){
          this.history.push(event.urlAfterRedirects);
        }
      });
    }

    public getHistory(): string[] {
      return this.history;
    }
  
    public getPreviousUrl(): string {
      if(this.history.length > 0){
        return this.history[this.history.length - 2];
      }
      return '';
    }


  /*private previousUrl: string;
  private currentUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }    */


} 