import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { PORTALTYPE } from 'app/core/helpers/enum.helper';
import { decodedToken } from 'app/core/helpers/token.helper';
import { AppLoaderService } from 'app/core/services/app-loader.service';
import { checkNull } from 'app/core/services/custom-validation';
import { GenerateRouteService } from 'app/core/services/generate-route.service';
import { MessagingService } from 'app/core/services/messaging.service';
import { ToastService } from 'app/core/services/toast.service';
import { NotificationsService } from 'app/modules/admin/notification/notification.service';
import { get } from "lodash";

@Component({
  selector: 'app-notifications-admin',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;
  dateNumber = Date.now();
  notifications = [];
  count: number = 0;
  portalType: any;
  checkType: any = PORTALTYPE;
  notifies = [];

  msg: any;
  isConnected: boolean = false;
  payLoad = [];
  schoolId
  customerId



  constructor(private router: Router,
    private notificationService: NotificationsService,
    private loader: AppLoaderService,
    private generateRouteService: GenerateRouteService,
    private messagingService: MessagingService,
    private toastr: ToastService,

  ) {
    this.getNotificationList();
    this.getCurrentNotification();
    this.removeNotificationBasedOnId();
    this.reloadNotify();
  }

  reloadNotify() {
    this.messagingService.reloadNotificationList.subscribe(res => {
      if (res) {
        this.getNotificationList();
      }
    })
  }

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
    const token = decodedToken();
    this.portalType = get(token, 'type') ?? '';

  }


  getCurrentNotification() {
    this.messagingService.currentMessage.subscribe(res => {
      if (res) {
        this.notifications.unshift(res);
      }
    })
  }

  getNotificationList() {
    this.notificationService.getNotificationList()
      .subscribe(res => {
        if (res.keyword == 'success') {
          this.notifications = res.data.filter(el => el.msg_read == 0);
          this.count = this.notifications.length;
          // console.log('Notifi ', this.notifications);
          // this.notifications.map(el => {
          //   console.log('--------- PAGE ------ ' ,el.page);
          // })
          this.generateRouteService.setQueueCount(this.count, true);
        } else {
          this.notifications = [];
        }
      });
  }

  clicked(index, row) {
    let obj = checkNull(row?.notification_id) ? {notificationId: row?.notification_id,type:'single'} : {randomId: row?.data?.random_id,type:'single'}
    this.notificationService.readNotification(obj)
      .subscribe(res => {
        // console.log(res);
      })
    this.notifications.splice(index, 1);
    this.messagingService.appendQueueCount(0);
    this.generateRouteService.notificationNavigate(row.portal, row.page, row.data);
    this.notificPanel.close();
  }

  checkNull(data: any) {
    return checkNull(data)
  }

  removeNotificationBasedOnId() {
    this.messagingService.removeMessage.subscribe(res => {
      if (res) {
        let index = this.notifications.findIndex(el => el.notification_id == res.notification_id);
        this.notifications.splice(index, 1);
      }
    })
  }


  viewMore() {
    this.router.navigate(['notification']);
  }

  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }

}
