import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function checkNull(data: any) {
  return data != null && data != undefined && data != "" && (Array.isArray(data) ? data.length > 0 : true)
}
export const validFormats = "image/jpeg,image/png,application/pdf,image/jpg,application/docx,application/msword,application/doc,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const fileFormats: any[] = [
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/doc",
  "application/msword",
  "application/docx",
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  "image/jpg",
  "image/JPEG",
  "image/PNG",
  "image/JPG",
];

export const validFormat: any[] = ["application/pdf",
  "application/doc",
  "application/msword",]
const audioFormats: any[] = [`audio/mpeg`, "audio/wav", "audio/mp3", "audio/m4a"];
const videoFormats: any[] = [`video/mp4`];
export const imageFormats: any[] = [`image/jpeg`, `image/png`, "image/jpg", "image/jfif"];
const documentFormats: any[] = [
  `application/pdf`,
  "text/csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",

];
const docs: any[] = ["application/doc",
  "application/msword",
  "application/docx", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]

export function checkFile(type) {
  let audio = audioFormats.includes(type);
  let image = imageFormats.includes(type);
  let video = videoFormats.includes(type);
  let pdf = documentFormats.includes(type);
  let doc = docs.includes(type)

  return audio ? "audio" : video ? "video" : image ? "image" : pdf ? "pdf" : doc ? 'doc' : "text";
}

export function fileSize(sizeInMB: number) {
  return sizeInMB * 1024 * 1024;
}

export const patterns: any = {};

export interface GTM {
  extras?: any;
  msg?: any;
  errorCode?: any;
  responseContent?: any;
}

export interface PHP {
  keyword?: 'success' | 'failure' | 'failed',
  message?: any;
  data: any[] | any
  count?: any
}

export class php {
  keyword?: 'success' | 'failure' | 'failed'
  message?: any;
  data: any[] | any
  count?: any
}

export interface GTMPhp {
  keyword?: 'success' | 'failure',
  message?: any;
  data: any[] | any
}

export interface GTM2 {
  path?: any;
  errorCode?: any;
  message?: any;
}

export function getFilePath(data: any): String {
  let str = data;
  let path = str.split("/");
  return path.at(-1);
}

export function getTypeOfFile(data: any) {
  let images = ["jpg", "jpeg", "png", 'jfif', "JPG", "JPEG", "PNG", 'JFIF'];
  let fileFormarts = ["pdf", 'doc', 'docx', 'DOC', 'DOCX'];
  let str = data;
  let path = checkNull(data) && str.includes(".") ? str.split(".").at(-1) : "";
  return images.includes(path) ? "image" : fileFormarts?.find(x => x == path) ? ((fileFormarts?.find(x => x == path).toLowerCase() == 'doc' || fileFormarts?.find(x => x == path).toLowerCase() == 'docx') ? 'doc' : "pdf") : "";
}

export function endedWith(num: any) {
  let number = String(num);
  let value = "";
  switch (true) {
    case number.endsWith("1"):
      value = `${num}st`;
      break;
    case number.endsWith("2"):
      value = `${num}nd`;
      break;
    case number.endsWith("3"):
      value = `${num}rd`;
      break;
    default:
      value = `${num}th`;
      break;
  }
  return value;
}


export const nameAlreadyExists: ValidatorFn = (form: FormGroup) => {
  // console.log(form.value)
  let value: any[] = form.value.labelForm
  let labels: any[] = [];
  let index: any[] = [];
  value.forEach((ele: any, inx: any) => {
    if (checkNull(ele?.label)) {
      if (!labels.includes(ele?.label)) {
        labels.push(ele.label)
      } else {
        index.push(inx)
      }
    }
  })
  if (checkNull(index.length > 0)) {
    return { alreadyexists: true, index: index }
  } else {
    return null
  }
}

export function nameAlreadyExists1(value: any): ValidatorFn {

  let labels = value
  console.log(labels)

  return (form: FormGroup): ValidationErrors | null => {

    return null
  }
}


export function queryCodeGenerator(num: number) {
  return num?.toString().length == 1 ? `REQ00${num}` : num.toString().length == 2 ? `REQ0${num}` : `REQ${num}`
}