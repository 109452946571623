import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Router } from "@angular/router";
import { PORTALTYPE } from "../helpers/enum.helper";

@Injectable({
    providedIn: "root"
})

export class GenerateRouteService {


    constructor(private route: Router) {

    }

    public queueCount = new ReplaySubject<any>(0);

    /** queue count */

    setQueueCount(property: any, storeProp: boolean = false) {
        if (storeProp) {
            localStorage.setItem('queueCount', JSON.stringify(property));
        }
        this.queueCount.next(property);
    }

    getQueueCount() {
        return this.queueCount;
    }

    notificationNavigate(portalType, page, data) {
        console.log(portalType, page, data)
        if (portalType == PORTALTYPE.ADMIN) {
            this.navigateRouteForAdmin(page, data);
        } else if (portalType == PORTALTYPE.EMPLOYEE) {
            this.navigateRouteForAdminEmp(page, data);
        } else if (portalType == PORTALTYPE.NBFC) {
            this.navigateRouteForAuthority(page, data);
        } else if (portalType == PORTALTYPE.NBFCEmployee) {
            this.navigateRouteForAuthorityEmp(page, data);

        }
    }

    navigateRouteForAdmin(page, data) {
        if (page == 'nbfc_Legal_opinion') {
            this.route.navigate(["/nbfc-legal-opinion/view-online"], { queryParams: { bankLegalOpinionId: data?.id ,randomId: data?.random_id}})
        } else if (page == 'nbfc_modt') {
            this.route.navigate(["/nbfc-modt/view-online"], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        } else if (page == 'nbfc_additional_modt') {
            this.route.navigate(["/nbfc-additional-modt/view-online"], { queryParams: { bankAddModtId: data?.id ,randomId: data?.random_id}})
        } else if (page == 'nbfc_receipt') {
            this.route.navigate(["/nbfc-receipt/view"], { queryParams: { receiptId: data?.id ,randomId: data?.random_id}})
        } else if (page == 'nbfc_made_over') {
            this.route.navigate(["/nbfc-madeover/view-online"], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        } else if (page == 'nbfc_yearlyenc') {
            this.route.navigate(["/nbfc-yearly-encumbrance-certificate/view-online"], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        } else if (page == 'nbfc_others') {
            this.route.navigate(["/nbfc-others/view-online"], { queryParams: { othersId: data?.id ,randomId: data?.random_id}})
        }
        // Banking Services
        else if (page == 'customer_banking_Legal_opinion') {
            this.route.navigate([`/legal-opinion/view`], { queryParams: { bankLegalOpinionId: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'banking_modt') {
            this.route.navigate([`/banking-modt/view-online`], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'banking_additional_modt') {
            this.route.navigate([`/banking-add-modt/view-online/${data?.id}`], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'banking_made_over') {
            this.route.navigate([`/madeover/view-online`], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'banking_receipt') {
            this.route.navigate([`/receipt/view`], { queryParams: { receiptId: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'banking_others') {
            this.route.navigate([`/banking-others/view-online/${data?.id}`], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'banking_yearlyenc') {
            this.route.navigate([`/yearly-encumbrance-certificate/view-online`], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        }

        // Individual Services
        else if (page == 'customer_individual_Legal_opinion') {
            this.route.navigate([`/individual-legal-opinion/view`], { queryParams: { bankLegalOpinionId: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'individual_modt') {
            this.route.navigate([`/individual-modt/view-online`], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'individual_receipt') {
            this.route.navigate([`/individual-receipt/view-online`], { queryParams: { receiptId: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'individual_doc_req') {
            this.route.navigate([`/documentation-requirements/view-online`], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        }
        else if (page == 'individual_legal_consultation') {
            this.route.navigate([`/individual-legal-consult/view-online/${data?.id}`], { queryParams: { id: data?.id ,randomId: data?.random_id}})
        }
        else {
            this.route.navigate(["/notification"]);
        }
    }

    navigateRouteForAdminEmp(page, idObj) {
        this.route.navigate([`/dietitian/info/list`])
    }

    navigateRouteForAuthority(page, idObj) {
        this.route.navigate([`/physiotherapist/info/list`])
    }

    navigateRouteForAuthorityEmp(page, idObj) {
        this.route.navigate([`/physiotherapist/info/list`])
    }

}