import { Component, OnInit, EventEmitter, Input, ViewChildren  , Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { decodedToken } from 'app/core/helpers/token.helper';
import { JwtAuthService } from 'app/core/services/auth/jwt-auth.service';
import { LayoutService } from 'app/core/services/layout.service';
import { MessagingService } from 'app/core/services/messaging.service';
import { ThemeService } from 'app/core/services/theme.service';
import { UserService } from 'app/core/services/user.service';
import { EmployeeService } from 'app/core/services/employee.service';
import { NotificationsComponent } from '../notifications/notifications.component';
import { NBFCService } from 'app/core/services/nbfc.service';
// import { tgssNotifications2Component } from '../tgss-notifications2/tgss-notifications2.component';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  @ViewChildren(NotificationsComponent) noti;
  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'ES',
    code: 'es',
    flag: 'flag-icon-es'
  }];
  currentLang = this.availableLangs[0];

  public tgssThemes;
  public layoutConf: any;
  userDetails: any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    public jwtAuth: JwtAuthService,
    private userservice: UserService,
    private employeeService: EmployeeService,
    private nbfcService: NBFCService,
    private messagingService: MessagingService,
  ) {}
  ngOnInit() {
    const token = window.localStorage.getItem("NEOLINETOKEN");
    this.messagingService.requestPermission(token);
    this.messagingService.receiveMessage();
    this.tgssThemes = this.themeService.tgssThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang.code);
    this.userDetails = decodedToken();
  }
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, {transitionClass: true});
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, {transitionClass: true});

  }

  onSearch(e) {
    //   console.log(e)
  }

  signout(){
    let token = decodedToken();
    this.messagingService.onLogout().subscribe();
    if(token.portal == 'employee'){
      this.employeeService.logout();
    }
    if(token.portal == 'nbfc'){
      this.nbfcService.logout();
    }
    else{
      this.userservice.logout();
    }
  }
}
