<div class="item">
    <mat-card>
       <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
             <div >
                <ngx-skeleton-loader  appearance="circle"
                   [theme]="{ 
                   'border-radius': '50%',
                   height: '200px',
                   width: '200px'
                   }"
                   ></ngx-skeleton-loader>
             </div>
             <div class="ml-40" >

                <div>
                    
                <ngx-skeleton-loader
                [theme]="{ 
                height: '30px',
                width: '300px'
                }"
                ></ngx-skeleton-loader>

                </div>
                <div>
                    
                    <ngx-skeleton-loader
                    [theme]="{ 
                    height: '30px',
                    width: '300px'
                    }"
                    ></ngx-skeleton-loader>
    
                    </div>
                    <div>
                    
                        <ngx-skeleton-loader
                        [theme]="{ 
                        height: '30px',
                        width: '300px'
                        }"
                        ></ngx-skeleton-loader>
        
                        </div>
                        <div>
                    
                            <ngx-skeleton-loader
                            [theme]="{ 
                            height: '30px',
                            width: '300px'
                            }"
                            ></ngx-skeleton-loader>
            
                            </div>
             
               
               
             </div>
          </div>
          <div class="ml-40">
            <div >

                <div>
                    
                <ngx-skeleton-loader
                [theme]="{ 
                height: '30px',
                width: '300px'
                }"
                ></ngx-skeleton-loader>

                </div>
                <div>
                    
                    <ngx-skeleton-loader
                    [theme]="{ 
                    height: '30px',
                    width: '300px'
                    }"
                    ></ngx-skeleton-loader>
    
                    </div>
                    <div>
                    
                        <ngx-skeleton-loader
                        [theme]="{ 
                        height: '30px',
                        width: '300px'
                        }"
                        ></ngx-skeleton-loader>
        
                        </div>
                        <div>
                    
                            <ngx-skeleton-loader
                            [theme]="{ 
                            height: '30px',
                            width: '300px'
                            }"
                            ></ngx-skeleton-loader>
            
                            </div>
             
               
               
             </div>
          </div>
          <div class="ml-40">
            <div >

                <div>
                    
                <ngx-skeleton-loader
                [theme]="{ 
                height: '30px',
                width: '300px'
                }"
                ></ngx-skeleton-loader>

                </div>
                <div>
                    
                    <ngx-skeleton-loader
                    [theme]="{ 
                    height: '30px',
                    width: '300px'
                    }"
                    ></ngx-skeleton-loader>
    
                    </div>
                    <div>
                    
                        <ngx-skeleton-loader
                        [theme]="{ 
                        height: '30px',
                        width: '300px'
                        }"
                        ></ngx-skeleton-loader>
        
                        </div>
                        <div>
                    
                            <ngx-skeleton-loader
                            [theme]="{ 
                            height: '30px',
                            width: '300px'
                            }"
                            ></ngx-skeleton-loader>
            
                            </div>
             
               
               
             </div>
          </div>


       </div>
    </mat-card>
 </div>