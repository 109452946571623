<div class="text-center">
    <!-- <h6 class="m-0 pb-16" style="white-space: pre-wrap; line-height: 1.4;">{{ title }}</h6> -->
    <div mat-dialog-content>
        <!-- <mat-spinner  class="mat-spinner-color" [style.margin]="'auto'"></mat-spinner> -->
        
              <div id="loader">
                  <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="loading"></div>
              </div>
            </div>
       
</div>
