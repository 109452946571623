import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "app/core/services/api.service";
import { EmployeeApiService } from "app/core/services/employeeapi.service";
import { Observable } from "rxjs";

function checnull(data: any) {
  if (data != null && data != undefined && data != "") {
    return true;
  } else {
    return false;
  }
}

@Injectable({
  providedIn: "root",
})
export class SelectboxService {
  constructor(
    private apiservice: ApiService,
    private employeeService: EmployeeApiService
  ) {}
  getdata(url: any, paramsDTIO?: any): Observable<any> {
    let params = new HttpParams();
    for (let data in paramsDTIO) {
      if (checnull(paramsDTIO[data])) {
        params = params.append(data, paramsDTIO[data]);
      }
    }
    return this.apiservice.get(url, params);
  }

  getData2(url: any, paramsDTIO?: any): Observable<any> {
    let params = new HttpParams();
    for (let data in paramsDTIO) {
      if (checnull(paramsDTIO[data])) {
        params = params.append(data, paramsDTIO[data]);
      }
    }
    return this.employeeService.get(url, params);
  }
}
