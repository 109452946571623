import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { LayoutService } from './core/services/layout.service';
import { NavigationService } from './core/services/navigation.service';
import { PermissionService } from './core/services/permission.service';
import { RoutePartsService } from './core/services/route-parts.service';
import { filter } from 'rxjs/operators';
import { getToken } from './core/helpers/token.helper';
import { ConfigService } from './core/services/config.service';
import { UILibIconService } from './core/services/ui-lib-icon.service';
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import {
  AppDateAdapter,
  APP_DATE_FORMATS,
} from "app/shared/helpers/format-datepicker";
import { AppServerDatePipe } from "app/shared/pipes/app-config.pipe";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    AppServerDatePipe,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Neoline';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private configService: ConfigService,
    private permSer: PermissionService,
    private navSer: NavigationService,
    private layout: LayoutService,
    private iconService: UILibIconService
  ) {
    iconService.init()

  }

  ngOnInit() {
    this.setPrefernce();

  }

  ngAfterViewInit() {
    this.changePageTitle();
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts;
      this.pageTitle = routeParts
                      .reverse()
                      .map((part) => part.title )
                      .reduce((partA, partI) => {return `${partA} > ${partI}`});
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }
  setPrefernce() {
    if (getToken()) {
      /* set preference */
      this.configService.init();
      let langPrefer = this.configService.language;
      let roleId = this.configService.roleId;
      let permission = this.configService.permission;
      let portalType = this.configService.portalType;
      let themeColor = this.configService.themeColour;
      // handle permission based menu
      this.permSer.init(Number(roleId), portalType, permission);
      let authMenu = this.permSer.authMenu;
      this.navSer.publish(authMenu);
      this.layout.publishLayoutChange({ matTheme: themeColor });
    }
  }
}
