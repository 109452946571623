<mat-form-field appearance="outline">
    <mat-label *ngIf="options">{{label|titlecase}}<span class="required" *ngIf="required">*</span></mat-label>
    <mat-label *ngIf="options==undefined">
        <div class="select-placeholder-container">
            <span>{{label|titlecase}} <span class="required" *ngIf="required">*</span></span>

        </div>
    </mat-label>
    <mat-select #select [formControl]="form_control" (selectionChange)="optionSelected($event)"
        (openedChange)="clearSearch()">
        <div class="filter-input multiselect-filter">

            <input matInput #searchInput class="mat-filter-input" [formControl]="searchControl"
                [placeholder]="placeholder" matInput type="search">

            <mat-icon matSuffix class="clear_icon cursor-pointer" *ngIf="searchInput.value!=''"
                (click)="searchInput.value='';clearSearch()">
                cancel
            </mat-icon>
        </div>
        <div class="" style="margin-top: 10px;" *ngFor="let data of filteredOptions">
            <mat-option *ngIf="options_display!=''&&options_value!=''&&nonSelectedOptions(data[options_value])"
                [value]="data[options_value]">
                {{data[options_display]}}
            </mat-option>
        </div>
        <div class="" style="margin-top: 10px;" *ngFor="let data of filteredOptions">
            <mat-option *ngIf="options_display==''&&options_value==''&&nonSelectedOptions(data)" [value]="data">
                {{data}}
            </mat-option>
        </div>


        <mat-option *ngIf="filteredOptions?.length<=0||filteredOptions==undefined">No data found...</mat-option>
    </mat-select>
    <mat-spinner matSuffix class="spinner" diameter="20" *ngIf="options==undefined"></mat-spinner>
    <span matSuffix *ngIf="form_control.hasError('required') && form_control.touched" matTooltip="{{error_message }}"
        [matTooltipDisabled]="!form_control.invalid">
        <mat-icon color="warn">info</mat-icon>
    </span>
    <span matSuffix
        *ngIf="formgroup_errors?.NeedDistricts && form_control.touched&&!form_control.hasError('required')&& form_control.touched"
        matTooltip="Please select the City!" [matTooltipDisabled]="!formgroup_errors?.NeedDistricts">
        <mat-icon color="warn">info</mat-icon>
    </span>
</mat-form-field>