<div class="item">
    <mat-card>

        <div class="d-flex-center">

            <div>
                <ngx-skeleton-loader  
                [theme]="{ 
               
                height: '40px',
                width: '400px'
                }"
                ></ngx-skeleton-loader>
            </div>
            <div class="ml-16">
                <ngx-skeleton-loader  
                [theme]="{ 
               
                height: '40px',
                width: '400px'
                }"
                ></ngx-skeleton-loader>
            </div>

         

          

        </div>
        
     
        <div class="d-flex-center">

            <div>
                <ngx-skeleton-loader  
                [theme]="{ 
               
                height: '160px',
                width: '160px'
                }"
                ></ngx-skeleton-loader>
            </div>
            <div class="ml-16">
                <ngx-skeleton-loader  
                [theme]="{ 
               
                height: '160px',
                width: '160px'
                }"
                ></ngx-skeleton-loader>
            </div>

         

          

        </div>

        
       
        


     
    </mat-card>
 </div>

