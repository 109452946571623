import { Routes } from "@angular/router";
import { AuthGuard } from "./core/services/auth-guard.service";
import { EmployeeAuthGuard } from "./core/services/employee-auth-guard.service";
import { NBFCAuthGuard } from "./core/services/nbfc-auth-guard.service";
import { NBFCEmployeeAuthGuard } from "./core/services/nbfc-employee-auth-guard.service";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { QueryAuthGuard } from "./core/services/query-auth-guard.service";
import { QueryHeadAuthGuard } from "./core/services/query-head-auth-guard.service";
import { QueryAdminAuthGuard } from "./core/services/query-admin-auth-guard.service";
import { SuperAdminAuthGuard } from "./core/services/sup-admin-auth-guard.service";
import { DataAdminAuthGuard } from "./core/services/data-admin-auth-guard.service";
import { DataEmpAuthGuard } from "./core/services/data-emp-auth-guard.service copy";
import { DataHeadAuthGuard } from "./core/services/data-head-auth-guard.service";

export const rootRouterConfig: Routes = [
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./modules/admin/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Session", breadcrumb: "Session" },
      },
      {
        path: "employee",
        loadChildren: () =>
          import("./modules/employee/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Employee", breadcrumb: "Session" },
      },
      {
        path: "nbfc",
        loadChildren: () =>
          import("./modules/nbfc/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "NBFC", breadcrumb: "NBFC" },
      },
      {
        path: "nbfc-employee",
        loadChildren: () =>
          import("./modules/nbfc-employee/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "NBFC Employee", breadcrumb: "NBFC Employee" },
      },
      {
        path: "query-employee",
        loadChildren: () =>
          import("./modules/query-employee/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Query Employee", breadcrumb: "Query Employee" },
      },
      {
        path: "query-head",
        loadChildren: () =>
          import("./modules/query-head/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Query Head", breadcrumb: "Query Head" },
      },
      {
        path: "query-admin",
        loadChildren: () =>
          import("./modules/query-admin/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Query Admin", breadcrumb: "Query Admin" },
      },
      {
        path: "super-admin",
        loadChildren: () =>
          import("./modules/super-admin/sessions/sessions.module").then(
            (m) => m.SessionsModule
          ),
        data: { title: "Super Admin", breadcrumb: "Super Admin" },
      },
      {
        path: "data-collection-employee",
        loadChildren: () =>
          import(
            "./modules/data-collection-employee/sessions/sessions.module"
          ).then((m) => m.SessionsModule),
        data: {
          title: "Data Collection Employee",
          breadcrumb: "Data Collection Employee",
        },
      },
      {
        path: "data-collection-admin",
        loadChildren: () =>
          import(
            "./modules/data-collection-admin/sessions/sessions.module"
          ).then((m) => m.SessionsModule),
        data: {
          title: "Data Collection Employee",
          breadcrumb: "Data Collection Employee",
        },
      },
      {
        path: "data-collection-head",
        loadChildren: () =>
          import(
            "./modules/data-collection-head/sessions/sessions.module"
          ).then((m) => m.SessionsModule),
        data: {
          title: "Data Collection Employee",
          breadcrumb: "Data Collection Employee",
        },
      },
    ],
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./modules/admin/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Home", breadcrumb: "Home" },
      },

      {
        path: "legal-opinion",
        loadChildren: () =>
          import("./modules/admin/legal-opinion/legal-opinion.module").then(
            (m) => m.LegalopinionModule
          ),
        data: { title: "Legal Opinion", breadcrumb: "Legal Opinion" },
      },

      {
        path: "madeover",
        loadChildren: () =>
          import("./modules/admin/madeover/madeover.module").then(
            (m) => m.MadeOverModule
          ),
        data: { title: "Made Over", breadcrumb: "Made Over" },
      },

      {
        path: "banking-modt",
        loadChildren: () =>
          import("./modules/admin/banking-modt/banking-modt.module").then(
            (m) => m.BankingModtModule
          ),
        data: { title: "Banking MODT", breadcrumb: "Banking MODT" },
      },
      {
        path: "banking-add-modt",
        loadChildren: () =>
          import(
            "./modules/admin/banking-add-modt/banking-add-modt.module"
          ).then((m) => m.BankingAddModtModule),
        data: {
          title: "Banking Additional MODT",
          breadcrumb: "Banking Addition MODT",
        },
      },

      {
        path: "receipt",
        loadChildren: () =>
          import("./modules/admin/receipt/receipt.module").then(
            (m) => m.ReceiptModule
          ),
        data: { title: "Receipt", breadcrumb: "Receipt" },
      },
      {
        path: "individual-receipt",
        loadChildren: () =>
          import(
            "./modules/admin/individual-receipt/individual-receipt.module"
          ).then((m) => m.IndividualReceiptModule),
        data: { title: "Individual Receipt", breadcrumb: "Individual Receipt" },
      },
      {
        path: "banking-others",
        loadChildren: () =>
          import("./modules/admin/banking-others/banking-others.module").then(
            (m) => m.BankingOthersModule
          ),
        data: { title: "Banking Others", breadcrumb: "Banking Others" },
      },
      {
        path: "nbfc-legal-opinion",
        loadChildren: () =>
          import(
            "./modules/admin/nbfc-legal-opinion/nbfc-legal-opinion.module"
          ).then((m) => m.NBFCLegalOpinionModule),
        data: { title: "NBFC Legal Opinion", breadcrumb: "NBFC Legal Opinion" },
      },
      {
        path: "nbfc-additional-modt",
        loadChildren: () =>
          import(
            "./modules/admin/nbfc-additional-modt/nbfc-additional-modt.module"
          ).then((m) => m.NBFCAdditionalMODTModule),
        data: {
          title: "NBFC Additional MODT",
          breadcrumb: "NBFC Additional MODT",
        },
      },
      {
        path: "nbfc-madeover",
        loadChildren: () =>
          import("./modules/admin/nbfc-madeover/nbfc-madeover.module").then(
            (m) => m.NBFCMadeOverModule
          ),
        data: { title: "NBFC Made Over", breadcrumb: "NBFC Made Over" },
      },
      {
        path: "nbfc-modt",
        loadChildren: () =>
          import("./modules/admin/nbfc-modt/nbfc-modt.module").then(
            (m) => m.NBFCMODTModule
          ),
        data: { title: "NBFC MODT", breadcrumb: "NBFC MODT" },
      },
      {
        path: "nbfc-receipt",
        loadChildren: () =>
          import("./modules/admin/nbfc-receipt/nbfc-receipt.module").then(
            (m) => m.NBFCReceiptModule
          ),
        data: { title: "NBFC Receipt", breadcrumb: "NBFC Receipt" },
      },
      {
        path: "nbfc-yearly-encumbrance-certificate",
        loadChildren: () =>
          import(
            "./modules/admin/nbfc-yearly-encumbrance-certificate/nbfc-yearly-encumbrance-certificate.module"
          ).then((m) => m.NBFCYearlyEncumbranceCertificateModule),
        data: {
          title: "NBFC Yearly Encumbrance Certificate",
          breadcrumb: "NBFC Yearly Encumbrance Certificate",
        },
      },
      {
        path: "nbfc-others",
        loadChildren: () =>
          import("./modules/admin/nbfc-others/nbfc-others.module").then(
            (m) => m.NBFCOthersModule
          ),
        data: { title: "NBFC Others", breadcrumb: "NBFC Others" },
      },
      {
        path: "payment-transaction",
        loadChildren: () =>
          import(
            "./modules/admin/payment-transaction/payment-transaction.module"
          ).then((m) => m.PaymentTransactionModule),
        data: {
          title: "Payment Transaction",
          breadcrumb: "Payment Transaction",
        },
      },

      {
        path: "individual-legal-opinion",
        loadChildren: () =>
          import(
            "./modules/admin/individual-legal-opinion/individual-legal-opinion.module"
          ).then((m) => m.IndividualLegalOpinionModule),
        data: {
          title: "Individual Legal Opinion",
          breadcrumb: "lndividual Legal Opinion",
        },
      },

      {
        path: "individual-modt",
        loadChildren: () =>
          import("./modules/admin/individual-modt/individual-modt.module").then(
            (m) => m.IndividualModtModule
          ),
        data: { title: "Individual MODT", breadcrumb: "Individual MODT" },
      },

      {
        path: "notification",
        loadChildren: () =>
          import("./modules/admin/notification/notification.module").then(
            (m) => m.NotificationModule
          ),
        data: { title: "Notification", breadcrumb: "Notification" },
      },

      {
        path: "documentation-requirements",
        loadChildren: () =>
          import(
            "./modules/admin/individual-documentation-requirements/documentation-requirements.module"
          ).then((m) => m.DocumentationRequirementsModule),
        data: {
          title: "Documentation Requirements",
          breadcrumb: "Documentation Requirements",
        },
      },

      {
        path: "word-form-management",
        loadChildren: () =>
          import(
            "./modules/admin/word-form-management/word-form-management.module"
          ).then((m) => m.WordFormManagementModule),
        data: {
          title: "word form management",
          breadcrumb: "word form management",
        },
      },
      {
        path: "yearly-encumbrance-certificate",
        loadChildren: () =>
          import(
            "./modules/admin/yearly-encumbrance-certificate/yearly-encumbrance-certificate.module"
          ).then((m) => m.YearlyEncumbranceCertificateModule),
        data: {
          title: "Yearly Encumbrance Certificate",
          breadcrumb: "Yearly Encumbrance Certificate",
        },
      },

      {
        path: "individual-legal-consult",
        loadChildren: () =>
          import(
            "./modules/admin/individual-legal-consultation/individual-legal-consultation.module"
          ).then((m) => m.IndividualLegalConsultationModule),
        data: { title: "legal conslut", breadcrumb: "legal conslut" },
      },
      {
        path: "individual-project",
        loadChildren: () =>
          import(
            "./modules/admin/individual-project/individual-project.module"
          ).then((m) => m.IndividualProjectModule),
        data: { title: "project", breadcrumb: "project" },
      },
      {
        path: "employee-task",
        loadChildren: () =>
          import("./modules/admin/employee-task/employee-task.module").then(
            (m) => m.EmployeeTaskModule
          ),
        data: { title: "Employee Task", breadcrumb: "Employee Task" },
      },

      {
        path: "",
        loadChildren: () =>
          import("./modules/admin/reports/reports.module").then(
            (m) => m.ReportsModule
          ),
        data: { title: "Reports", breadcrumb: "Reports" },
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./modules/admin/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
        data: { title: "settings", breadcrumb: "settings" },
      },
      {
        path: "opinion-employee/banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/opinion-employee/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.OpinionEmpLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "opinion-head/banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/opinion-head/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.OpinionHeadLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "opinion-admin/banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/opinion-admin/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.OpinionAdminLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "opinion-employee/individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/opinion-employee/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.OpinionEmpIndividualLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "qc-head/banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/qc-head/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.QcHeadLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "qc-employee/banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/qc-employee/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.QCEmpLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "qc-employee/individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/qc-employee/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.QCEmpIndLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "qc-admin/banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/qc-admin/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.QCAdminLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "qc-admin/individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/qc-admin/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.QCAdminIndividualLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
      path: "signing-authority-admin/banking/legal-opinion",
      loadChildren: () =>
        import(
          "./modules/signing-authority-admin/banking/legal-opinion/legal-opinion.module"
        ).then((m) => m.SigningAuthorityLegalopinionModule),
      data: { title: "Legal Opinion" },
    },
    {
      path: "signing-authority-admin/individual/legal-opinion",
      loadChildren: () =>
        import(
          "./modules/signing-authority-admin/individual/legal-opinion/legal-opinion.module"
        ).then((m) => m.SigningAuthorityIndividualLegalopinionModule),
      data: { title: "Legal Opinion" },
    },
    {
      path: "signing-authority-head/banking/legal-opinion",
      loadChildren: () =>
        import(
          "./modules/signing-authority-head/banking/legal-opinion/legal-opinion.module"
        ).then((m) => m.SigningAuthorityHeadLegalopinionModule),
      data: { title: "Legal Opinion" },
    },
    {
      path: "signing-authority-head/individual/legal-opinion",
      loadChildren: () =>
        import(
          "./modules/signing-authority-head/individual/legal-opinion/legal-opinion.module"
        ).then((m) => m.SigningAuthorityHeadIndividualLegalopinionModule),
        data: { title: "Legal Opinion" },
    },
      {
        path: "vetting-report/banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/vetting-report/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.VettingReportLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "vetting-report/individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/vetting-report/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.VettingReportIndLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
    ],
  },
  {
    path: "employee",
    component: AdminLayoutComponent,
    canActivate: [EmployeeAuthGuard],
    children: [
      {
        path: "task-manager",
        loadChildren: () =>
          import("./modules/employee/task-manager/task-manager.module").then(
            (m) => m.TaskmanagerModule
          ),
        data: { title: "Employee Home" },
      },
    ],
  },
  {
    path: "nbfc",
    component: AdminLayoutComponent,
    canActivate: [NBFCAuthGuard],

    children: [
      // {
      //   path: "home",
      //   loadChildren: () =>
      //     import("./modules/nbfc/dashboard/dashboard.module").then(
      //       (m) => m.DashboardModule
      //     ),
      //   data: { title: "NBFC Home" },
      // },
      {
        path: "legal-opinion-nbfc",
        loadChildren: () =>
          import(
            "./modules/nbfc/legal-opinion-nbfc/legal-opinion-nbfc.module"
          ).then((m) => m.LegalopinionNBFCModule),
        data: { title: "Legal Opinion NBFC", breadcrumb: "Legal Opinion NBFC" },
      },
      {
        path: "modt",
        loadChildren: () =>
          import("./modules/nbfc/modt-nbfc/modt-nbfc.module").then(
            (m) => m.ModtNBFCModule
          ),
        data: { title: "MODT NBFC", breadcrumb: "MODT NBFC" },
      },
      {
        path: "additional-modt",
        loadChildren: () =>
          import(
            "./modules/nbfc/additional-modt-nbfc/additional-modt-nbfc.module"
          ).then((m) => m.AdditionalModtNBFCModule),
        data: {
          title: "Additional MODT NBFC",
          breadcrumb: "Additional MODT NBFC",
        },
      },
      {
        path: "receipt",
        loadChildren: () =>
          import("./modules/nbfc/receipt-nbfc/receipt-nbfc.module").then(
            (m) => m.ReceiptNBFCModule
          ),
        data: { title: "Receipt NBFC", breadcrumb: "Receipt NBFC" },
      },
      {
        path: "madeover",
        loadChildren: () =>
          import("./modules/nbfc/madeover-nbfc/madeover-nbfc.module").then(
            (m) => m.MadeOverNBFCModule
          ),
        data: { title: "Made Over NBFC", breadcrumb: "Made Over NBFC" },
      },
      {
        path: "yearly-encumbrance-certificate",
        loadChildren: () =>
          import(
            "./modules/nbfc/yearly-encumbrance-certificate-nbfc/yearly-encumbrance-certificate-nbfc.module"
          ).then((m) => m.YearlyEncumbranceCertificateNBFCModule),
        data: {
          title: "Yearly Encumbrance Certificate NBFC",
          breadcrumb: "Yearly Encumbrance Certificate NBFC",
        },
      },
      {
        path: "employee",
        loadChildren: () =>
          import("./modules/nbfc/employee-nbfc/employee-nbfc.module").then(
            (m) => m.EmployeeNBFCModule
          ),
        data: { title: "Employee NBFC", breadcrumb: "Employee NBFC" },
      },
      {
        path: "others",
        loadChildren: () =>
          import("./modules/nbfc/others-nbfc/others-nbfc.module").then(
            (m) => m.OthersNBFCModule
          ),
        data: { title: "Others NBFC", breadcrumb: "Others NBFC" },
      },
    ],
  },

  {
    path: "nbfc-employee",
    component: AdminLayoutComponent,
    canActivate: [NBFCEmployeeAuthGuard],
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./modules/nbfc-employee/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "NBFC Employee Home" },
      },
      {
        path: "employee-legal-opinion",
        loadChildren: () =>
          import(
            "./modules/nbfc-employee/employee-legal-opinion/employee-legal-opinion.module"
          ).then((m) => m.EmployeeLegalopinionModule),
        data: {
          title: "Employee Legal Opinion",
          breadcrumb: "Employee Legal Opinion",
        },
      },
      {
        path: "employee-modt",
        loadChildren: () =>
          import(
            "./modules/nbfc-employee/modt-employee/employee-modt.module"
          ).then((m) => m.EmployeeModtModule),
        data: { title: "Employee MODT", breadcrumb: "Employee MODT" },
      },
      {
        path: "employee-additional-modt",
        loadChildren: () =>
          import(
            "./modules/nbfc-employee/additional-modt-employee/employee-additional-modt.module"
          ).then((m) => m.EmployeeAdditionalModtModule),
        data: {
          title: "Employee Additional MODT",
          breadcrumb: "Employee Additional MODT",
        },
      },
      {
        path: "employee-receipt",
        loadChildren: () =>
          import(
            "./modules/nbfc-employee/receipt-employee/receipt-employee.module"
          ).then((m) => m.EmployeeReceiptModule),
        data: { title: "Employee Receipt", breadcrumb: "Employee Receipt" },
      },
      {
        path: "employee-madeover",
        loadChildren: () =>
          import(
            "./modules/nbfc-employee/madeover-employee/madeover-employee.module"
          ).then((m) => m.EmployeeMadeOverModule),
        data: { title: "Employee MadeOver", breadcrumb: "Employee MadeOver" },
      },
      {
        path: "employee-yearly-encumbrance-certificate",
        loadChildren: () =>
          import(
            "./modules/nbfc-employee/yearly-encumbrance-certificate-employee/employee-yearly-encumbrance-certificate.module"
          ).then((m) => m.EmployeeYearlyEncumbranceCertificateModule),
        data: {
          title: "Employee Yearly Encumbrance Certificate",
          breadcrumb: "Employee Yearly Encumbrance Certificate",
        },
      },
      {
        path: "employee-others",
        loadChildren: () =>
          import(
            "./modules/nbfc-employee/others-employee/employee-others.module"
          ).then((m) => m.EmployeeOthersModule),
        data: { title: "Employee Others", breadcrumb: "Employee Others" },
      },
      {
        path: "**",
        redirectTo: "sessions/404",
      },
    ],
  },

  {
    path: "query-employee",
    component: AdminLayoutComponent,
    canActivate: [QueryAuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/query-employee/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Dashboard" },
      },
      {
        path: "banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/query-employee/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.QueryEmpLegalopinionModule),
        data: { title: "Query Employee - Legal Opinion" },
      },
      {
        path: "modt",
        loadChildren: () =>
          import("./modules/query-employee/banking/modt/modt.module").then(
            (m) => m.QueryEmpMODTModule
          ),
        data: { title: "Query Employee - MODT" },
      },
      {
        path: "individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/query-employee/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.QueryEmpLegalopinionModule),
        data: { title: "Query Employee - Legal Opinion" },
      },
      {
        path: "individual/modt",
        loadChildren: () =>
          import("./modules/query-employee/individual/modt/modt.module").then(
            (m) => m.QueryEmpMODTModule
          ),
        data: { title: "Query Employee - MODT" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },

  {
    path: "query-head",
    component: AdminLayoutComponent,
    canActivate: [QueryHeadAuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/query-head/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Dashboard" },
      },
      {
        path: "banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/query-head/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.LegalOpinionModule),
        data: { title: "Legal Opinion", breadcrumb: "Legal Opinion" },
      },
      {
        path: "banking/modt",
        loadChildren: () =>
          import("./modules/query-head/banking/modt/modt.module").then(
            (m) => m.MODTModule
          ),
        data: { title: "MODT", breadcrumb: "MODT" },
      },
      {
        path: "individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/query-head/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.IndividualLegalOpinionModule),
        data: { title: "Legal Opinion", breadcrumb: "Legal Opinion" },
      },
      {
        path: "individual/modt",
        loadChildren: () =>
          import("./modules/query-head/individual/modt/modt.module").then(
            (m) => m.IndividualMODTModule
          ),
        data: { title: "MODT", breadcrumb: "MODT" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },

  {
    path: "query-admin",
    component: AdminLayoutComponent,
    canActivate: [QueryAdminAuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/query-admin/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Dashboard" },
      },
      {
        path: "banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/query-admin/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.LegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/query-admin/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.IndividualLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
  {
    path: "super-admin",
    component: AdminLayoutComponent,
    canActivate: [SuperAdminAuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/super-admin/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Dashboard" },
      },
      {
        path: "banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/super-admin/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.LegalOpinionModule),
        data: { title: "Legal Opinion", breadcrumb: "Legal Opinion" },
      },
      {
        path: "banking/modt",
        loadChildren: () =>
          import("./modules/super-admin/banking/modt/modt.module").then(
            (m) => m.MODTModule
          ),
        data: { title: "MODT", breadcrumb: "MODT" },
      },
      {
        path: "individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/super-admin/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.SuperAdminIndividualLegalOpinionModule),
        data: { title: "Legal Opinion", breadcrumb: "Legal Opinion" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
  {
    path: "data-collection-employee",
    component: AdminLayoutComponent,
    canActivate: [DataEmpAuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./modules/query-admin/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
        data: { title: "Dashboard" },
      },
      {
        path: "banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/data-collection-employee/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.DataCollectionLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/data-collection-employee/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.DataCollectionIndiLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
  {
    path: "data-collection-admin",
    component: AdminLayoutComponent,
    canActivate: [DataAdminAuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import(
            "./modules/data-collection-admin/dashboard/dashboard.module"
          ).then((m) => m.DashboardModule),
        data: { title: "Dashboard" },
      },
      {
        path: "banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/data-collection-admin/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.DataCollectionLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/data-collection-admin/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.DataCollectionIndividualLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
  {
    path: "data-collection-head",
    component: AdminLayoutComponent,
    canActivate: [DataHeadAuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import(
            "./modules/data-collection-head/dashboard/dashboard.module"
          ).then((m) => m.DashboardModule),
        data: { title: "Dashboard" },
      },
      {
        path: "banking/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/data-collection-head/banking/legal-opinion/legal-opinion.module"
          ).then((m) => m.DataCollectionLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
      {
        path: "individual/legal-opinion",
        loadChildren: () =>
          import(
            "./modules/data-collection-head/individual/legal-opinion/legal-opinion.module"
          ).then((m) => m.DataCollectionIndividualLegalopinionModule),
        data: { title: "Legal Opinion" },
      },
    ],
  },
];
