import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedMaterialModule } from "../shared-material.module";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SearchModule } from "../search/search.module";
import { SharedPipesModule } from "../pipes/shared-pipes.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedDirectivesModule } from "../directives/shared-directives.module";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./header-side/header-side.component";
import { SidebarSideComponent } from "./sidebar-side/sidebar-side.component";

// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from "./header-top/header-top.component";
import { SidebarTopComponent } from "./sidebar-top/sidebar-top.component";

// ONLY FOR DEMO
import { CustomizerComponent } from "./customizer/customizer.component";

// ALWAYS REQUIRED
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { FooterComponent } from "./footer/footer.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { ButtonLoadingComponent } from "./button-loading/button-loading.component";
import { BottomSheetShareComponent } from "./bottom-sheet-share/bottom-sheet-share.component";
import {
  tgssSidebarComponent,
  tgssSidebarTogglerDirective,
} from "./tgss-sidebar/tgss-sidebar.component";
import { tgssExampleViewerComponent } from "./example-viewer/example-viewer.component";
import { tgssExampleViewerTemplateComponent } from "./example-viewer-template/example-viewer-template.component";
import { TgssEditorModule } from "./tgss-editor/tgss-editor.module";
import { ViewSkeletonLoaderComponent } from "./view-skeleton-loader/view-skeleton-loader.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ActualSkeletonLoaderComponent } from "./actual-skeleton-loader/actual-skeleton-loader.component";
import { PrepareSkeletonLoaderComponent } from "./prepare-skeleton-loader/prepare-skeleton-loader.component";
import { ReferSkeletonLoaderComponent } from "./refer-skeleton-loader/refer-skeleton-loader.component";
import { TGSSSelectBoxV2Module } from "./tgss-select-box-v2/tgss-select-box-v2/tgss-select-box-v2.module";
import { TgssSelectBoxModule } from "./tgss-select-box/tgss-select-box.module";
import { TimeagoModule } from "ngx-timeago";
const components = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  CustomizerComponent,
  ButtonLoadingComponent,
  tgssSidebarComponent,
  FooterComponent,
  tgssSidebarTogglerDirective,
  BottomSheetShareComponent,
  tgssExampleViewerComponent,
  tgssExampleViewerTemplateComponent,
  ViewSkeletonLoaderComponent,
  ActualSkeletonLoaderComponent,
  PrepareSkeletonLoaderComponent,
  ReferSkeletonLoaderComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SearchModule,
    SharedPipesModule,
    TgssEditorModule,
    SharedDirectivesModule,
    SharedMaterialModule,
    TGSSSelectBoxV2Module,
    TgssSelectBoxModule,
    TimeagoModule.forRoot()
  ],
  declarations: components,
  // entryComponents: [AppComfirmComponent, AppLoaderComponent, BottomSheetShareComponent],
  exports: components,
})
export class SharedComponentsModule { }
