import { SPACE } from "@angular/cdk/keycodes";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AbstractControl, FormControl, Validators } from "@angular/forms";
import { MatOption } from "@angular/material/core";
import { debounceTime } from "rxjs/operators";
import { SelectboxService } from "../selectbox.service";
function checkNull(value) {
  if (value != null && value != undefined && value != "") {
    return true;
  } else {
    return false;
  }
}

@Component({
  selector: "app-multi-select-apioptions",
  templateUrl: "./multi-select-apioptions.component.html",
  styleUrls: ["./multi-select-apioptions.component.css"],
})
export class MultiSelectApioptionsComponent
  implements OnInit, OnChanges, AfterViewInit
{
  //SEARCH INPUT PLACEHOLDER
  @Input("placeholder") public placeholder: any;

  //MAT-FORM-FIELD-LABEL
  @Input("label") public label: any;

  // //OPTIONS FROM PARENT COMPONENT
  // @Input('options') public options: any[];

  //ALL OPTIONS IS NEEDED OR NOT
  @Input("need_all_options") public need_all_options: boolean;

  //OPTIONS URL FROM PARENT COMPONENT
  @Input("api_endpoint") public api_endpoint: string;

  //RESPONSE KEY FROM PARENT COMPONENT
  @Input(`response_key`) public response_key: string;

  //ERROR MESSAGE FROM PARENT COMPONENT
  @Input("error_message") public error_message: any;

  //VALIDATORS REQUIRED [TRUE OR FALSE]
  @Input("required") public required: boolean;

  //CONFIRMATION FROM PARENT WHETHER THE SELECTBOX IS MULTIPLE OR NOT
  @Input(`multiple`) private multiple: boolean = true;

  //VIEW VALUE FOR THE SELECT FROM THE OPTIONS ARRAY
  @Input("options_display") public options_display: any;

  //VALUE FOR THE SELECT FROM THE OPTIONS ARRAY
  @Input("options_value") public options_value: any;

  //ABSTRACT CONTROL OF THE PARENT FROMGROUP
  @Input("form_control") public form_control: AbstractControl;

  //EMITS VALUE WHEN A VALUE IS SELECTED
  @Output("on_select") public on_select: EventEmitter<any> = new EventEmitter();

  @Input("portal") public portal: any;

  /**********************************The above variables are for the PARENT COMPONENT*********************************************/

  public searchControl: FormControl;
  public filteredOptions: any[] = [];
  public selectedValues: any[] = [];
  public options: any[] = [];
  public load: boolean = false;
  @ViewChild("select", { static: true }) select: any;
  @ViewChild("allOption") public allOption: MatOption;
  constructor(private selectService: SelectboxService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!checkNull(this.need_all_options)) {
      this.need_all_options = false;
    }
    if (this.api_endpoint != "" && this.api_endpoint != undefined) {
      this.getdata();
    }
  }

  ngOnInit(): void {
    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.keyCode == SPACE) return;
      if (!this.select.disabled) {
        this.select.panelOpen
          ? this.select._handleOpenKeydown(event)
          : this.select._handleClosedKeydown(event);
      }
    };
    this.UIapperance();
  }

  UIapperance() {
    this.searchControl = new FormControl("");
    //lable
    this.label = this.label ? this.label : "Select-v2";

    //placeHolder
    this.placeholder = this.placeholder ? this.placeholder : "Search";
    //required
    switch (this.required) {
      case true:
        {
          this.form_control.setValidators([Validators.required]);
          this.form_control.updateValueAndValidity();
          this.required = true;
        }
        break;
      default:
        {
          this.form_control.clearValidators();
          this.form_control.updateValueAndValidity();
          this.required = false;
        }
        break;
    }
  }

  ngAfterViewInit(): void {
    this.searchOptions();
  }

  searchOptions() {
    this.searchControl.valueChanges.pipe(debounceTime(100)).subscribe((res: any) => {
      let value: string = res;
      this.setSelectedValues();
      this.form_control.patchValue(this.selectedValues);
      if (this.options.length > 0) {
        if (this.options_display != "" && this.options_value != "") {
          this.filteredOptions = this.options.filter((ele: any) =>
            String(ele[this.options_display])
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        } else {
          this.filteredOptions = this.options.filter((ele: any) =>
            String(ele).toLowerCase().includes(value.toLowerCase())
          );
        }
      }
    });
  }

  optionSelected(event: any) {
    this.on_select.emit({ value: event?.value });
  }

  clearSearch() {
    this.searchControl.setValue("");
    this.selectedValues = this.form_control.value;
    this.filteredOptions = this.options;
  }

  setSelectedValues() {
    if (this.form_control.value && this.form_control.value.length > 0) {
      this.form_control.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  selectionChange(event: any): void {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
    }
  }

  selectAll(event) {
    if (event.checked) {
      this.selectedValues = this.options.map((ele: any) =>
        this.options_value != "" ? ele[this.options_value] : ele
      );
      this.form_control.patchValue(this.selectedValues);
    } else {
      this.selectedValues = [];
      this.form_control.patchValue(this.selectedValues);
    }
  }

  getdata() {
    if (this.portal != "employee") {
      this.load = true;
      this.selectService.getdata(this.api_endpoint).subscribe(
        (res: any) => {
          this.load = false;
          if (res?.errorCode == 200) {
            this.options = res[this.response_key];
            this.filteredOptions = res[this.response_key];
          } else {
            this.options = [];
            this.filteredOptions = [];
          }
        },
        (err: any) => {
          this.load = false;
          this.options = [];
          this.filteredOptions = [];
        }
      );
    } else {
      this.load = true;
      this.selectService.getData2(this.api_endpoint).subscribe(
        (res: any) => {
          this.load = false;
          if (res?.errorCode == 200) {
            this.options = res[this.response_key];
            this.filteredOptions = res[this.response_key];
          } else {
            this.options = [];
            this.filteredOptions = [];
          }
        },
        (err: any) => {
          this.load = false;
          this.options = [];
          this.filteredOptions = [];
        }
      );
    }
  }
}
