<div class="text-center dark-bg pt-16 pb-16">
  <h6 class="m-0 text-pink">Notifications</h6>
</div>
<mat-nav-list class="notification-list" role="list">
  <!-- Notification item -->
  <mat-list-item *ngFor="let n of notifications | slice:0:15; let i = index"  class="notific-item" role="listitem" routerLinkActive="open">
    <img src="assets/images/question-mark.png" width="30" height="30">
    <a (click)="clicked(i,n)">
      <div class="mat-list-text">
        <h4 class="message">{{n.title}}</h4>
        <small class="time text-muted">{{ n.created_on ? (n.created_on | timeago:live) : ( dateNumber | timeago:live) }}</small>
      </div>
    </a>
  </mat-list-item>
</mat-nav-list>
<div class="text-center mt-16 view-more" *ngIf="notifications.length">
  <small><a (click)="viewMore()"  >View More</a></small>
</div>


<!-- <div class="text-center mat-bg-primary pt-1 pb-1">
  <h6 class="m-0">Notifications</h6>
</div>
<mat-nav-list class="notification-list" role="list">
  
  <mat-list-item *ngFor="let n of notifications | slice:0:15; let i = index" class="notific-item" role="listitem">
 
    <img [src]="n.page ? 'assets/images/notification-icons/' + n.page + '.png' : 'assets/images/notification-icons/sample.png'" width="30" height="30">
    <a (click)="clicked(i,n)">
      <div class="mat-list-text">
        <h4 class="message text-left pl-12" style="text-align:left;">{{n.title}}</h4>
        <small class="time text-muted pl-12">{{ n.created_on ? (n.created_on | timeago:live) : ( dateNumber | timeago:live) }}</small>
      </div>
    </a> 
    <br>
  </mat-list-item>
</mat-nav-list>

<div class="text-center mt-1">
  <small><a (click)="viewMore()">View More</a></small>
</div> -->