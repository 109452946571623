import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { A, Z, ZERO, NINE, SPACE, I } from '@angular/cdk/keycodes';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SearchDTO } from 'app/shared/models/Search/Search';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-tgss-select-box',
  templateUrl: './tgss-select-box.component.html',
  styleUrls: ['./tgss-select-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TgssSelectBoxComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TgssSelectBoxComponent),
      multi: true,
    },
  ],
})
export class TgssSelectBoxComponent implements OnInit, AfterViewInit, OnChanges {

  /***********
  * @default ElementRef
  * @memberof TgssSelectBoxComponent
  ***********/

  @ViewChild('filter', { static: false }) filter: ElementRef;

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description Select Box appearance
  ***********/
  @Input() appearance: any = 'outline';

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description Select Box lable
  ***********/
  @Input() public lable: String;

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description Set The name For name Attribute
  ***********/
  @Input() public name: String;

  /***********
  * @default FormControl
  * @memberof TgssSelectBoxComponent
  * @description FormControl for Selectbox
  ***********/
  @Input() public formControlNames: FormControl;

  /***********
  * @default []
  * @memberof TgssSelectBoxComponent
  * @description Select Box Array
  ***********/
  @Input() public array: any;

  /***********
  * @default []
  * @memberof TgssSelectBoxComponent
  * @description Select Box Helpper Array
  ***********/
  public helperarray: any = [];

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We want to need value of selected Option Set ifFindValue = true 
  ***********/
  @Input() public ifFindValue: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We want to need value of selected Option by using click event to set ifclickevent = true
  ***********/
  @Input() public ifclickevent: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We want to need value of selected Option by using OnSlectionChange event to set ifonSelectionChange = true
  ***********/
  @Input() public ifonSelectionChange: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We want to need value of selected Option by using SlectionChange event to set ifSelectionChange = true
  ***********/
  @Input() public ifSelectionChange: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We don't need any event use to set ifnoevent = true
  ***********/
  @Input() public ifnoevent: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We need Required Symbol set isRequired = true
  ***********/
  @Input() public isRequired: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We need Validation based selectbox set isValidation = true. If We need Design based Selectbox set isValidation = false
  ***********/
  @Input() public isValidation: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We Use the selectbox in multiple row or FormArray set ifMultiRow = true
  ***********/
  @Input() public ifMultiRow: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We Use the single selectbox set ifSingle = true
  ***********/
  @Input() public ifSingle: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If enable multi select set ismultiple = true
  ***********/
  @Input() public ismultiple: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We need api based search and infinitescroll to set isapisearch = true
  ***********/
  @Input() public isapisearch: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We need search input in selectbox to set issearch = true
  ***********/
  @Input() public issearch: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description If We need All option in selectbox to set ispresentall = true
  ***********/
  @Input() public ispresentall: boolean = false;

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description Error message
  ***********/
  @Input() public toolTips: any;

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description Add Your Class in select box
  ***********/
  @Input() public class: any;

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description Select Option Field Name and this is mandatory to show your option
  ***********/
  @Input() public textField: any;

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description Select Option value and this is mandatory to show your option
  ***********/
  @Input() public textFieldValue: any;

  /***********
  * @default 'Search...'
  * @memberof TgssSelectBoxComponent
  * @description PlaceHolder for the Selectbox
  ***********/
  @Input() placeholder: any;

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description PlaceHolder for the Selectbox
  ***********/
  @Input() Selectplaceholder: any = '';

  /***********
  * @default 'white'
  * @memberof TgssSelectBoxComponent
  * @description set baackground color for searchfield
  ***********/
  @Input() color: any;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description Set If true it just show the Spinner near by search field
  ***********/
  @Input() showSpinner: boolean = false;

  /***********
  * @default 'No results...'
  * @memberof TgssSelectBoxComponent
  * @description Set No data FounD message
  ***********/
  @Input() noResultsMessage: any;

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description It used for set FormArray control name in multiple row
  ***********/
  @Input() public formCtrName: any;

  /***********
  * @default FormArray
  * @memberof TgssSelectBoxComponent
  * @description FormArray 
  ***********/
  @Input() public formArray: FormArray;

  /***********
  * @default ''
  * @memberof TgssSelectBoxComponent
  * @description Index of FormArray 
  ***********/
  @Input() public Index: any;

  /***********
  * @default EventEmitter
  * @memberof TgssSelectBoxComponent
  * @description Default Array based filter emit the value in filteredReturn
  ***********/
  @Output() filteredReturn = new EventEmitter();

  /***********
  * @default EventEmitter
  * @memberof TgssSelectBoxComponent
  * @description While search any value the text emit in searchEvent
  ***********/
  @Output() searchEvent = new EventEmitter();

  /***********
  * @default EventEmitter
  * @memberof TgssSelectBoxComponent
  * @description ifSelectionChange == true the value will be emit into selectionChangeevent
  ***********/
  @Output() selectionChangeevent = new EventEmitter();

  /***********
  * @default EventEmitter
  * @memberof TgssSelectBoxComponent
  * @description ifclickevent == true the value will be emit into clickevent
  ***********/
  @Output() clickevent = new EventEmitter();

  /***********
  * @default EventEmitter
  * @memberof TgssSelectBoxComponent
  * @description ifonSelectionChange == true the value will be emit into onselectionChangeevent
  ***********/
  @Output() onselectionChangeevent = new EventEmitter();

  /***********
  * @default EventEmitter
  * @memberof TgssSelectBoxComponent
  * @description While scrolling the selectbox  value will be emit into onScrollevent
  ***********/
  @Output() onScrollevent = new EventEmitter();

  /***********
  * @default EventEmitter
  * @memberof TgssSelectBoxComponent
  * @description While scrolling the selectbox  value will be emit into onScrollevent
  ***********/
  @Output() clearEvent = new EventEmitter();

  /***********
  * @default FormControl
  * @memberof TgssSelectBoxComponent
  * @description FormControl for search input field
  ***********/
  value: FormControl;

  /***********
  * @default FormControl
  * @memberof TgssSelectBoxComponent
  * @description FormControl for Api based search and infinitescroll
  ***********/
  apiselect = new FormControl();

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description While search value not found it will set in to true
  ***********/
  noResults: boolean = false;

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description compare with option
  ***********/
  @Input() public iscomparewith: boolean = false;


  /***********
  * @default fale
  * @memberof TgssSelectBoxComponent
  * @description Enable Spinner while search the value in input
  ***********/
  localSpinner: boolean = false;

  /***********
  * @default []
  * @memberof TgssSelectBoxComponent
  * @description After Filtered the items can be stored in filteredItems
  ***********/
  filteredItems: any = [];

  /***********
  * @default FormGroup
  * @memberof TgssSelectBoxComponent
  * @description Search Input Form
  ***********/
  searchForm: FormGroup;

  /***********
  * @default Model
  * @memberof TgssSelectBoxComponent
  * @description Searach Interface for normal select box
  ***********/
  searchDTO: SearchDTO;

  /***********
  * @default MatSelect
  * @memberof TgssSelectBoxComponent
  * @description Using for scroll up the scroll bar during the search
  ***********/
  @ViewChild('optioncontainer') private optionContainer: MatSelect;
  @ViewChild('Selector',{static:false}) private select: any;

  /***********
  * @default []
  * @memberof TgssSelectBoxComponent
  * @description used to set the selected value to formcontrol
  ***********/
  selecedValues: any = [];

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description used for disable infinite scroll
  ***********/
  iscomplete: boolean = false;

  /***********
  * @default []
  * @memberof TgssSelectBoxComponent
  * @description Used for Load the selected option into chip
  ***********/
  selectedOptions: any = [];

  /***********
  * @default false
  * @memberof TgssSelectBoxComponent
  * @description Used for Show and hide the more selected chip
  ***********/
  isShowChip: boolean = false;
  searchValue: any;

  constructor(
    public fb: FormBuilder,
  ) {
    /***********
    * @description Defaultly set showSpinner as true
    ***********/
    this.showSpinner = true;

    /***********
    * @description Defaultly set noResults as false
    ***********/
    this.noResults = false;

    /***********
    * @description Defaultly set localSpinner as false
    ***********/
    this.localSpinner = false;

    this.filteredReturn = new EventEmitter<any>();

    /***********
    * @description Declar the searchForm
    ***********/
    this.searchForm = this.fb.group({
      value: new FormControl('')
    });

    /***********
    * @description Assign Search Model
    ***********/
    this.searchDTO = new SearchDTO();
  }

  ngOnInit(): void {

    setTimeout(() => {
      this.filter?.nativeElement.focus();
    }, 500);

    if (this.isValidation == true && this.ifMultiRow == false) {
      this.helperarray = this.array;
    }

    if (this.isValidation == true && this.ifMultiRow == true) {
      this.helperarray[this.Index] = this.array?.value ? this.array?.value : [];
    }
    /**************** Design Based Filter **************/
    if (this.isValidation == false) {
      this.helperarray = this.array;
      this.searchForm.valueChanges.subscribe(value => {
        this.inarrayFilter(value);
      });
    }
    
    /**************** 18-11-2022 ***************/
    if (this.ifMultiRow == true && this.ismultiple) {
      this.selecedValues = this.formArray.controls[this.Index].get(`${this.formCtrName}`).value ? this.formArray.controls[this.Index].get(`${this.formCtrName}`).value : [];
    }
    /**************** 18-11-2022 ***************/
    
  }

  /******************* Refresh the Array Value during the Search *********/
  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      if (this.isapisearch == false) {
        if (!changes.array?.firstChange) {
          if (changes.array) {
            if (this.ifMultiRow == false) {
              this.array = changes.array.currentValue ? changes.array.currentValue : [];
              this.helperarray = this.array;
              if (this.helperarray.length == 0) {
                this.noResults = true;
              }
              else {
                this.noResults = false;
              }
            }
            else {
              /**************** 18-11-2022 ***************/
              if (this.ifMultiRow == true && this.ismultiple) {
                this.selecedValues = this.formArray.controls[this.Index].get(`${this.formCtrName}`).value ? this.formArray.controls[this.Index].get(`${this.formCtrName}`).value : [];
              }
              /**************** 18-11-2022 ***************/
              
              if (changes?.array?.currentValue.index == this.Index) {
                this.array = changes.array.currentValue.value;
                this.helperarray[changes.array.currentValue.index] = this.array;
              }
              if (this.helperarray[changes.array.currentValue.index].length == 0) {
                this.noResults = true;
              }
              else {
                this.noResults = false;
              }
              this.stopsearch();
            }


          }
        }
        else {
          // if(changes?.formArray?.currentValue != undefined){
          //   changes?.formArray?.currentValue.controls.filter((res, inx) => {
          //     if (changes?.array?.currentValue.index == changes?.Index?.currentValue) {
          //       this.array = changes.array.currentValue.value;
          //       this.helperarray = this.array;
          //     }
          //   });
          // }
          // else if(this.ifMultiRow == true){
          //   if (changes?.array?.currentValue.index == this.Index) {
          //     this.array = changes.array.currentValue.value;
          //     this.helperarray = this.array;
          //   }
          // }
        }
      }
    }, 0);
  }


  /************** AfterViewInit for Validation Based Search ***************/
  ngAfterViewInit() {
   
    this.filterKeyUp();
  }

  /**************** filter List ***********/
  async filterKeyUp() {
    if (this.filter?.nativeElement) {
      fromEvent(this.filter?.nativeElement, "keydown")
        .pipe(debounceTime(350), distinctUntilChanged())
        .subscribe((event: any) => {
          console.log(event.keyCode );
          
          if ((event.key && event.key.length === 1) ||
            (event.keyCode >= A && event.keyCode <= Z) ||
            (event.keyCode >= ZERO && event.keyCode <= NINE) ||
            (event.keyCode === SPACE)) {
            event.stopPropagation();
          
          }
            if (this.isapisearch == true) {
              this.searchEvent.emit({ component: this, text: this.filter.nativeElement.value })
            }
            else {
              if (this.isValidation == true) {
                this.inarrayFilter(this.filter.nativeElement.value);
              }
            }
         
        });
    }
  }


  /************** Filter in In array *************/
  inarrayFilter(value) {
    if (this.showSpinner) {
      this.startsearch();
    }
    if (this.ifMultiRow == false) {
      if (value['value'] ? value['value'] : value) {
        this.searchValue = value['value'] ? value['value'] : value;
        setTimeout(() => {
          this.filteredItems = this.array.filter(name => name[`${this.textField}`].toLowerCase().includes((value['value'] ? value['value'] : value).toLowerCase()));
          this.noResults = this.filteredItems == null || this.filteredItems.length === 0;
          this.helperarray = this.filteredItems;
          if (this.showSpinner) {
            this.stopsearch();
          }
        }, 1000);

      }
      else {
        setTimeout(() => {
          this.searchValue = '';
          this.filteredItems = this.array.slice();
          this.noResults = false;
          this.helperarray = this.array;
          if (this.showSpinner) {
            this.stopsearch();
          }
        }, 1000)
      }
    }
    else {
      this.searchEvent.emit({ component: this, text: value['value'] ? value['value'] : value })
    }
    this.scrolloption();
  }


  /************** Scroll Up the selectbox Scroller ************/
  scrolloption() {
    if (this.optionContainer !== undefined) {
      try {
        setTimeout(() => {
          this.optionContainer.panel.nativeElement.scrollTop = 0;
        }, 100);
      } catch (error) {
        console.warn(error);
      }
    }
  }

  /*********** Filtered Value ****************/
  handleKeydown(event) {
    if ((event.key && event.key.length === 1) ||
      (event.keyCode >= A && event.keyCode <= Z) ||
      (event.keyCode >= ZERO && event.keyCode <= NINE) ||
      (event.keyCode === SPACE)) {
      event.stopPropagation();
    }
    if (event.keyCode === 8) {
      let helperarray = this.array;
      this.searchForm.valueChanges.subscribe(value => {
        if (this.showSpinner) {
          this.startsearch();
        }
        if (value['value']) {
          setTimeout(() => {
            this.filteredItems = helperarray.filter(name => name[`${this.textField}`].toLowerCase().includes(value['value'].toLowerCase()));
            this.noResults = this.filteredItems == null || this.filteredItems.length === 0;
            if (this.showSpinner) {
              this.stopsearch();
            }
          }, 1000);
        }
        else {
          setTimeout(() => {
            this.filteredItems = helperarray.slice();
            this.noResults = false;
            if (this.showSpinner) {
              this.stopsearch();
            }
          }, 1000);
        }
        this.helperarray = this.filteredItems;
      });
    }
    this.scrolloption();
  }

  /*************** Clear Input Value While select Has been open and close *******/
  clearInputValu(event) {
    this.searchDTO.search = '';
    this.ifMultiRow == true ? this.searchDTO.index = this.Index : '';
    if (this.isValidation == true) {
      if (!event) {
        if (this.searchForm.get('value').value != '') {
          this.searchForm.get('value').setValue('');
          if (this.isapisearch == false) {
            if (this.ifMultiRow == false) {
              this.helperarray = this.array;
            }
            else {
              this.searchEvent.emit({ component: this, text: this.searchForm.get('value').value });
            }
          }
          else {
            this.searchEvent.emit({ component: this, text: this.searchForm.get('value').value });
          }
        }
      }
    }
    else {
      if (!event) {
        if (this.searchForm.get('value').value != '') {
          this.searchForm.get('value').setValue('');
          this.helperarray = this.array;
        }
      }
    }
    this.isShowChip = false;
    if (this.selectedOptions.length == 0) {
      this.apiselect.setValidators(Validators.required);
      this.apiselect.updateValueAndValidity();
      this.apiselect.markAsTouched();
    }
  }

  /******************* Reload Array*****************/
  Reload() {
    if (this.isapisearch == true) {
      this.scrolloption();
      this.searchForm.get('value').setValue('');
      this.searchEvent.emit({ component: this, text: this.searchForm.get('value').value });
    }
  }

  /*************** Get Selection Change Value ************/
  getSelectionChangeValue(value) {

    if (this.isapisearch == false) {
      if (this.ismultiple == true) {
        if(this.ispresentall == true){
          if(value == 'all'){
            if (this.selecedValues.includes('all')) {
              this.selecedValues = [];
            }
            else{
              this.selecedValues = [...(this.ifMultiRow  == true ? this.helperarray[this.Index] : this.helperarray).map((res) => res[this.textFieldValue])];
            }
          }
          else{
            if (!this.selecedValues.some((res) => res == value)) {
              this.selecedValues.push(value);
              if(this.selecedValues.length == (this.ifMultiRow  == true ? this.helperarray[this.Index] : this.helperarray).filter((res) => res[this.textFieldValue] != 'all').length){
                this.selecedValues.push('all');
              }
            }
            else {
              let index = this.selecedValues.findIndex((res) => res == value);
              this.selecedValues.splice(index, 1);
              let inx = this.selecedValues.findIndex((res) => res == 'all');
              if(inx != -1){
                this.selecedValues.splice(inx, 1);
              }
            }
          }
        }
        else{
          if (!this.selecedValues.some((res) => res == value)) {
            this.selecedValues.push(value);
          }
          else {
            let index = this.selecedValues.findIndex((res) => res == value);
            this.selecedValues.splice(index, 1);
          }
        }
        this.formControlNames.setValue(this.selecedValues.length > 0 ? this.selecedValues : [value]);
      }
      else {
        this.selectionChangeevent.emit(value);
      }
    }
    else {
      if (this.ifSingle == true) {
        if (this.ismultiple == false) {
          if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
            let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.push(findData);
          }
          this.formControlNames.setValue(value);
        }
        else {
          if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
            let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.push(findData);
          }
          else {
            let inx = this.selectedOptions.findIndex((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.splice(inx, 1);
          }
          let ids: any = [];
          this.selectedOptions.filter((res) => {
            ids.push(res[`${this.textFieldValue}`]);
          });
          this.formControlNames.setValue(value);
        }
      }
      else {
        if (this.ismultiple == false) {
          if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
            let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.push(findData);
          }
          this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue(value);
        }
        else {
          if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
            let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.push(findData);
          }
          else {
            let inx = this.selectedOptions.findIndex((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.splice(inx, 1);
          }
          let ids: any = [];
          this.selectedOptions.filter((res) => {
            ids.push(res[`${this.textFieldValue}`]);
          });
          this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue(ids);
        }
      }
      if (this.ismultiple == false) {
        this.apiselect.setValue('');
      }
      this.apiselect.clearValidators();
      this.apiselect.updateValueAndValidity();
      this.apiselect.markAsUntouched();
    }
  }

  /********************** Get clicked value **************/
  getclickedvalue(value) {
    if (this.isValidation == true) {
      if (this.isapisearch == false) {
          if (this.ismultiple == true) {
            if(this.ispresentall == true){
              if(value == 'all'){
                if (this.selecedValues.includes('all')) {
                  this.selecedValues = [];
                }
                else{
                  this.selecedValues = this.searchValue ? [...(this.ifMultiRow == true ? this.array[this.Index] : this.array).map((res) => res[this.textFieldValue])] : [...(this.ifMultiRow  == true ? this.helperarray[this.Index] : this.helperarray).map((res) => res[this.textFieldValue])];
                }
              }
              else{
                if (!this.selecedValues.some((res) => res == value)) {
                  this.selecedValues.push(value);
                  if(this.selecedValues.length == (this.ifMultiRow  == true ? this.helperarray[this.Index] : this.helperarray).filter((res) => res[this.textFieldValue] != 'all').length){
                    this.selecedValues.push('all');
                  }
                }
                else {
                  let index = this.selecedValues.findIndex((res) => res == value);
                  this.selecedValues.splice(index, 1);
                  let inx = this.selecedValues.findIndex((res) => res == 'all');
                  if(inx != -1){
                    this.selecedValues.splice(inx, 1);
                  }
                }
              }
            }
            else{
              if (!this.selecedValues.some((res) => res == value)) {
                this.selecedValues.push(value);
              }
              else {
                let index = this.selecedValues.findIndex((res) => res == value);
                this.selecedValues.splice(index, 1);
              }
            }
            this.formControlNames.setValue(this.selecedValues.length > 0 ? this.selecedValues : []);
            this.clickevent.emit({ component: this, text: this.searchForm.get('value').value, value: value });
          }
          else {
            this.clickevent.emit(value);
          }
      }
      else {
        if (this.ifSingle == true) {
          if (this.ismultiple == false) {
            if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
              let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.push(findData);
            }
            this.formControlNames.setValue(value);
          }
          else {
            if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
              let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.push(findData);
            }
            else {
              let inx = this.selectedOptions.findIndex((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.splice(inx, 1);
            }
            let ids: any = [];
            this.selectedOptions.filter((res) => {
              ids.push(res[`${this.textFieldValue}`]);
            });
            this.formControlNames.setValue(value);
          }
        }
        else {
          if (this.ismultiple == false) {
            if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
              let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.push(findData);
            }
            this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue(value);
          }
          else {
            if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
              let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.push(findData);
            }
            else {
              let inx = this.selectedOptions.findIndex((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.splice(inx, 1);
            }
            let ids: any = [];
            this.selectedOptions.filter((res) => {
              ids.push(res[`${this.textFieldValue}`]);
            });
            this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue(ids);
          }
        }
        if (this.ismultiple == false) {
          this.apiselect.setValue('');
        }
        this.apiselect.clearValidators();
        this.apiselect.updateValueAndValidity();
        this.apiselect.markAsUntouched();
      }
    }
    else {
      if (this.ismultiple == true) {
        if (!this.selecedValues.some((res) => res == value)) {
          this.selecedValues.push(value);
        }
        else {
          let index = this.selecedValues.findIndex((res) => res == value);
          this.selecedValues.splice(index, 1);
        }
        this.optionContainer.value = this.selecedValues.length > 0 ? this.selecedValues : [value];
      }
      else {
        this.clickevent.emit(value);
      }
    }
  }

  /********************** Get clicked value **************/
  getOnSelectionChangevalue(value) {
    if (this.isapisearch == false) {
      if (this.ismultiple == true) {
        if(this.ispresentall == true){
          if(value == 'all'){
            if (this.selecedValues.includes('all')) {
              this.selecedValues = [];
            }
            else{
              this.selecedValues = [...(this.ifMultiRow  == true ? this.helperarray[this.Index] : this.helperarray).map((res) => res[this.textFieldValue])];
            }
          }
          else{
            if (!this.selecedValues.some((res) => res == value)) {
              this.selecedValues.push(value);
              if(this.selecedValues.length == (this.ifMultiRow  == true ? this.helperarray[this.Index] : this.helperarray).filter((res) => res[this.textFieldValue] != 'all').length){
                this.selecedValues.push('all');
              }
            }
            else {
              let index = this.selecedValues.findIndex((res) => res == value);
              this.selecedValues.splice(index, 1);
              let inx = this.selecedValues.findIndex((res) => res == 'all');
              if(inx != -1){
                this.selecedValues.splice(inx, 1);
              }
            }
          }
        }
        else{
          if (!this.selecedValues.some((res) => res == value)) {
            this.selecedValues.push(value);
          }
          else {
            let index = this.selecedValues.findIndex((res) => res == value);
            this.selecedValues.splice(index, 1);
          }
        }
        this.formControlNames.setValue(this.selecedValues.length > 0 ? this.selecedValues : [value]);
      }
      else {
        this.onselectionChangeevent.emit(value);
      }
    }
    else {
      if (this.ifSingle == true) {
        if (this.ismultiple == false) {
          if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
            let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.push(findData);
          }
          this.formControlNames.setValue(value);
        }
        else {
          if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
            let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.push(findData);
          }
          else {
            let inx = this.selectedOptions.findIndex((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.splice(inx, 1);
          }
          let ids: any = [];
          this.selectedOptions.filter((res) => {
            ids.push(res[`${this.textFieldValue}`]);
          });
          this.formControlNames.setValue(value);
        }
      }
      else {
        if (this.ismultiple == false) {
          if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
            let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.push(findData);
          }
          this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue(value);
        }
        else {
          if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
            let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.push(findData);
          }
          else {
            let inx = this.selectedOptions.findIndex((res) => res[`${this.textFieldValue}`] == value);
            this.selectedOptions.splice(inx, 1);
          }
          let ids: any = [];
          this.selectedOptions.filter((res) => {
            ids.push(res[`${this.textFieldValue}`]);
          });
          this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue(ids);
        }
      }
      if (this.ismultiple == false) {
        this.apiselect.setValue('');
      }
      this.apiselect.clearValidators();
      this.apiselect.updateValueAndValidity();
      this.apiselect.markAsUntouched();
    }
  }

  /************* Store Selected Value ************/
  Setselectedvalue(value) {
    if (this.isapisearch == false) {
      if (this.ismultiple == true) {
        if(this.ispresentall == true){
          if(value == 'all'){
            if (this.selecedValues.includes('all')) {
              this.selecedValues = [];
            }
            else{
              this.selecedValues = [...(this.ifMultiRow  == true ? this.helperarray[this.Index] : this.helperarray).map((res) => res[this.textFieldValue])];
            }
          }
          else{
            if (!this.selecedValues.some((res) => res == value)) {
              this.selecedValues.push(value);
              if(this.selecedValues.length == (this.ifMultiRow  == true ? this.helperarray[this.Index] : this.helperarray).filter((res) => res[this.textFieldValue] != 'all').length){
                this.selecedValues.push('all');
              }
            }
            else {
              let index = this.selecedValues.findIndex((res) => res == value);
              this.selecedValues.splice(index, 1);
              let inx = this.selecedValues.findIndex((res) => res == 'all');
              if(inx != -1){
                this.selecedValues.splice(inx, 1);
              }
            }
          }
        }
        else{
          if (!this.selecedValues.some((res) => res == value)) {
            this.selecedValues.push(value);
          }
          else {
            let index = this.selecedValues.findIndex((res) => res == value);
            this.selecedValues.splice(index, 1);
          }
        }
        this.formControlNames.setValue(this.selecedValues.length > 0 ? this.selecedValues : [value]);
      }
    }
    else {
      if (this.isValidation == true) {
        if (this.ifSingle == true) {
          if (this.ismultiple == false) {
            if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
              let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.push(findData);
            }
            this.formControlNames.setValue(value);
          }
          else {
            if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
              let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.push(findData);
            }
            else {
              let inx = this.selectedOptions.findIndex((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.splice(inx, 1);
            }
            let ids: any = [];
            this.selectedOptions.filter((res) => {
              ids.push(res[`${this.textFieldValue}`]);
            });
            this.formControlNames.setValue(value);
          }
        }
        else {
          if (this.ismultiple == false) {
            if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
              let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.push(findData);
            }
            this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue(value);
          }
          else {
            if (!this.selectedOptions.some((res) => res[`${this.textFieldValue}`] == value)) {
              let findData = this.array.find((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.push(findData);
            }
            else {
              let inx = this.selectedOptions.findIndex((res) => res[`${this.textFieldValue}`] == value);
              this.selectedOptions.splice(inx, 1);
            }
            let ids: any = [];
            this.selectedOptions.filter((res) => {
              ids.push(res[`${this.textFieldValue}`]);
            });
            this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue(ids);
          }
        }
      }
      if (this.ismultiple == false) {
        this.apiselect.setValue('');
      }
      this.apiselect.clearValidators();
      this.apiselect.updateValueAndValidity();
      this.apiselect.markAsUntouched();
    }
  }

  /*************** Clear Input ************/
  clearInput() {
    if (this.searchForm.get('value').value != '') {
      this.searchForm.get('value').setValue('');
      if (this.isapisearch == true) {
        this.ifMultiRow == true ? this.searchDTO.index = this.Index : '';
        this.searchEvent.emit({ component: this, text: this.searchForm.get('value').value });
      }
      else {
        if (this.ifMultiRow == true) {
          this.searchEvent.emit({ component: this, text: this.searchForm.get('value').value });
        }
        else {
          this.helperarray = this.array;
        }
      }
    }
    this.noResults = false;
  }

  /**************** Clear form Value **********/
  clearvalue(type) {
    if (type == 1) {
      this.formControlNames.setValue('');
      this.formControlNames.updateValueAndValidity();
      this.formControlNames.markAsTouched();
      this.selecedValues = [];
      this.clearEvent.emit({ component: this, text: this.searchForm.get('value').value });
    }
    else {
      this.optionContainer.value = undefined;
      this.selecedValues = [];
    }
  }

  /*************** Infinit Scroll *************/
  onScroll() {
    this.onScrollevent.emit({ component: this, text: this.searchForm.value.value })
  }

  /*************** Stop Infinit Scroll *************/
  stopInfinitescroll() {
    this.iscomplete = true;
  }

  /*************** Star Infinit Scroll *************/
  startInfinitescroll() {
    this.iscomplete = false;
  }

  /*************** Start search *************/
  startsearch() {
    this.localSpinner = true;
  }

  /*************** Stop search *************/
  stopsearch() {
    this.localSpinner = false;
  }

  /************** Remove Chip ***********/
  removeChip(value, index) {
    if (this.ifSingle == true) {
      if (this.ismultiple == false) {
        this.selectedOptions = [];
        this.formControlNames.setValue('');
        this.formControlNames.updateValueAndValidity();
        this.formControlNames.markAsTouched();
      }
      else {
        this.selectedOptions.splice(index, 1);
        this.formControlNames.setValue(this.selectedOptions);
        this.formControlNames.updateValueAndValidity();
      }
    }
    else {
      if (this.ismultiple == false) {
        this.selectedOptions.splice(index, 1);
        this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue('');
        this.formArray.controls[this.Index].get(`${this.formCtrName}`).updateValueAndValidity();
        this.formArray.controls[this.Index].get(`${this.formCtrName}`).markAsTouched();
      }
      else {
        this.selectedOptions.splice(index, 1);
        this.formArray.controls[this.Index].get(`${this.formCtrName}`).setValue(this.selectedOptions);
        this.formArray.controls[this.Index].get(`${this.formCtrName}`).updateValueAndValidity();
        this.formArray.controls[this.Index].get(`${this.formCtrName}`).markAsTouched();
      }
    }
    if (this.selectedOptions.length == 1) {
      this.isShowChip = false;
    }
    if (this.selectedOptions.length == 0) {
      this.apiselect.setValidators(Validators.required);
      this.apiselect.updateValueAndValidity();
      this.apiselect.markAsTouched();
    }
  }

  /************** Reset Multi Select Value After Selection ********/
  resetMultiSelect() {
    if (this.ismultiple == true && this.isapisearch == true) {
      this.apiselect.setValue('');
    }
  }

  /************ Show More Chip **********/
  showChip() {
    this.isShowChip = true;
  }

  /*************** Hide Chip List **********/
  closeChip() {
    this.isShowChip = false;
  }

  /************* Object Comparison Function ***********/
  public objectComparisonFunctions = function (option, value): boolean {
    let isoptionmatch: any = [];
    Object.keys(option).filter((res) => {
      isoptionmatch.push(option[res] === value[res]);
    });
    return isoptionmatch.includes(false) ? false : true;
  }

  public objectComparisonFunction = function (option, value): boolean {
    return false;
  }

}
