<div class="item">
    <mat-card>

        <div class="d-flex">
            <div class="d-flex" >

                <div >
                    <div>
                        <ngx-skeleton-loader  
                        [theme]="{ 
                       
                        height: '40px',
                        width: '250px'
                        }"
                        ></ngx-skeleton-loader>
                    </div>
                    <div>
                        <ngx-skeleton-loader  
                        [theme]="{ 
                       
                        height: '40px',
                        width: '250px'
                        }"
                        ></ngx-skeleton-loader>
                    </div>

                </div>

                <div class="ml-16">
                    <ngx-skeleton-loader  
                    [theme]="{ 
                   
                    height: '160px',
                    width: '160px'
                    }"
                    ></ngx-skeleton-loader>
                </div>
               


            </div>

            <div class="ml-50">

                <div >
                    <div>
                        <ngx-skeleton-loader  
                        [theme]="{ 
                       
                        height: '200px',
                        width: '750px'
                        }"
                        ></ngx-skeleton-loader>
                    </div>
                 

                </div>



            </div>




        </div>

        <div>
            <div >
                <ngx-skeleton-loader  
                [theme]="{ 
               
                height: '60px',
                width: '100%'
                }"
                ></ngx-skeleton-loader>
            </div>


        </div>

     
        
     
     

        
       
        


     
    </mat-card>
 </div>

