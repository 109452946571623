import { HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { checkNull } from 'app/core/services/custom-validation';
import { Phpapiservice } from 'app/core/services/php-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  readonly notificationList$: EventEmitter<any> = new EventEmitter<any>();
  constructor(private apiservice: Phpapiservice) { } 

    getNotificationList(): Observable<any> {
      return this.apiservice.get(`AP/adminNotificationList`)
  }

  readNotification(formData: any): Observable<any> {
    return this.apiservice.post(`AP/readNotification`, formData)
  }
}
