<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button *ngIf="layoutConf.sidebarStyle !== 'compact'" class="side_nav_icon" mat-icon-button id="sidenavToggle"
    (click)="toggleSidenav()" matTooltip="Toggle Hide/Open">
    <mat-icon>menu</mat-icon>
  </button>
  <!-- <mat-form-field appearance="none" class="mt-8 common-search">
    <span matPrefix class="mr-8">
      <mat-icon>search</mat-icon>
    </span>
    <input matInput placeholder="Search here..." />
  </mat-form-field> -->
  <span fxFlex></span>
  <!-- Language Switcher -->
  <!-- <button mat-button [matMenuTriggerFor]="menu">
    <span class="flag-icon {{currentLang.flag}} mr-8"></span>
    <span>{{currentLang.name}}</span>
  </button>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
        <span class="flag-icon mr-8 {{lang.flag}}"></span>
        <span>{{lang.name}}</span>
  </button>
</mat-menu> -->


  <!-- <tgss-search-input-over placeholder="Country (e.g. US)" resultPage="/search">
  </tgss-search-input-over> -->
  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <button mat-icon-button matTooltip="Notifications" (click)="toggleNotific()" [style.overflow]="'visible'"
    class="topbar-button-right">
    <mat-icon>notifications</mat-icon>
    <span class="notification-number mat-bg-warn">3</span>
  </button>
  <!-- Top left user menu -->
  <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="topbar-button-right img-button">
    <img src="{{userDetails.portal == 'venue_owner' ?  userDetails?.profile_image : 'assets/images/avatar-bg.png'}}"
      alt="" />
  </button>

  <mat-menu #accountMenu="matMenu" backdropClass="header-mat-menu">
    <!-- <button mat-menu-item [routerLink]="['/profile/overview']">
      <mat-icon>account_box</mat-icon>
      <span>Profile</span>
    </button> -->

    <button mat-menu-item (click)="signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>