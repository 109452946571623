<mat-form-field appearance="{{ appearance }}" class="{{ class }}" *ngIf="isValidation == true && isapisearch == false">
  <mat-label>
    {{ lable }}
    <span *ngIf="isRequired && lable != undefined && lable != null" class="required_symbol">
      *
    </span>
  </mat-label>
  <input name="{{ lable }}" style="display: none;" [formControl]="formControlNames" />
  <mat-select *ngIf="!iscomparewith" placeholder="{{ Selectplaceholder ? Selectplaceholder : '' }}" #optioncontainer multiple="{{ ismultiple }}" (openedChange)="clearInputValu($event)" #Selector
    [formControl]="formControlNames" (selectionChange)="
      ifSelectionChange ? getSelectionChangeValue($event.value) : ''
    ">
    <form *ngIf="issearch" [formGroup]="searchForm" class="mat-filter"
      [ngStyle]="{ 'background-color': color ? color : 'white' }">
      <div class="d-flex">
        <input #filter class="mat-filter-input" matInput placeholder="{{ placeholder ? placeholder : 'Search...' }}"
          formControlName="value" />
        <mat-icon (click)="Reload()" *ngIf="!localSpinner && filter.value == '' && isapisearch == true">
          autorenew
        </mat-icon>
        <mat-icon (click)="clearInput()" *ngIf="filter.value != '' && !localSpinner">
          cancel
        </mat-icon>
        <mat-spinner *ngIf="localSpinner" class="spinner" diameter="16"></mat-spinner>
      </div>
      <div *ngIf="noResults" class="noResultsMessage">
        {{ noResultsMessage ? noResultsMessage : 'No results...' }}
      </div>
    </form>
    <mat-option (onSelectionChange)="ifonSelectionChange ? getOnSelectionChangevalue(item[textFieldValue]) : ''"
      (click)="ifclickevent ? getclickedvalue(item[textFieldValue]) : 
      !ifclickevent && !ifonSelectionChange && !ifSelectionChange && ifnoevent ? Setselectedvalue(item[textFieldValue]) : ''"
      *ngFor="let item of (ifMultiRow  == true ? helperarray[Index] : helperarray)" [value]="item[textFieldValue]">
      {{ item[textField] }}
    </mat-option>
  </mat-select>

  <mat-select *ngIf="iscomparewith" [compareWith]="iscomparewith ? objectComparisonFunctions : objectComparisonFunction" placeholder="{{ Selectplaceholder ? Selectplaceholder : '' }}" #optioncontainer multiple="{{ ismultiple }}" (openedChange)="clearInputValu($event)" #Selector
  [formControl]="formControlNames" (selectionChange)="
    ifSelectionChange ? getSelectionChangeValue($event.value) : ''
  ">
  <form *ngIf="issearch" [formGroup]="searchForm" class="mat-filter"
    [ngStyle]="{ 'background-color': color ? color : 'white' }">
    <div class="d-flex">
      <input #filter class="mat-filter-input" matInput placeholder="{{ placeholder ? placeholder : 'Search...' }}"
        formControlName="value" />
      <mat-icon (click)="Reload()" *ngIf="!localSpinner && filter.value == '' && isapisearch == true">
        autorenew
      </mat-icon>
      <mat-icon (click)="clearInput()" *ngIf="filter.value != '' && !localSpinner">
        cancel
      </mat-icon>
      <mat-spinner *ngIf="localSpinner" class="spinner" diameter="16"></mat-spinner>
    </div>
    <div *ngIf="noResults" class="noResultsMessage">
      {{ noResultsMessage ? noResultsMessage : 'No results...' }}
    </div>
  </form>
  <mat-option (onSelectionChange)="ifonSelectionChange ? getOnSelectionChangevalue(item[textFieldValue]) : ''"
    (click)="ifclickevent ? getclickedvalue(item[textFieldValue]) : 
    !ifclickevent && !ifonSelectionChange && !ifSelectionChange && ifnoevent ? Setselectedvalue(item[textFieldValue]) : ''"
    *ngFor="let item of (ifMultiRow  == true ? helperarray[Index] : helperarray)" [value]="item[textFieldValue]">
    {{ item[textField] }}
  </mat-option>
</mat-select>

  <mat-icon (click)="clearvalue(1)" class="clear-value"
    *ngIf="formControlNames.value != '' && formControlNames.value != undefined && formControlNames.value != null">
    cancel
  </mat-icon>
  <span matSuffix *ngIf="formControlNames.hasError('required') && formControlNames.touched" matTooltip="{{ toolTips }}"
    [matTooltipDisabled]="!formControlNames.invalid">
    <mat-icon color="warn">info</mat-icon>
  </span>
</mat-form-field>

<mat-form-field appearance="{{ appearance }}" class="full-width" *ngIf="isValidation == false">
  <mat-label>
    {{ lable }}
    <span *ngIf="isRequired && lable != undefined && lable != null" class="required_symbol">
      *
    </span>
  </mat-label>
  <mat-select placeholder="{{ Selectplaceholder ? Selectplaceholder : '' }}" multiple="{{ ismultiple }}"
    #optioncontainer (openedChange)="clearInputValu($event)">
    <form *ngIf="issearch" [formGroup]="searchForm" class="mat-filter"
      [ngStyle]="{ 'background-color': color ? color : 'white' }">
      <div class="filter-input">
        <input #filter class="mat-filter-input" matInput (keydown)="handleKeydown($event)"
          placeholder="{{ placeholder ? placeholder : 'Search...' }}" formControlName="value" />
        <mat-icon (click)="clearInput()" *ngIf="filter.value != '' && !localSpinner">
          cancel
        </mat-icon>
        <mat-spinner *ngIf="localSpinner" class="spinner" diameter="16"></mat-spinner>
      </div>
      <div *ngIf="noResults" class="noResultsMessage">
        {{ noResultsMessage ? noResultsMessage : 'No results...' }}
      </div>
    </form>
    <mat-option (click)="getclickedvalue(item[textFieldValue])" *ngFor="let item of helperarray"
      [value]="item[textFieldValue]">
      {{ item[textField] }}
    </mat-option>
  </mat-select>
  <mat-icon (click)="clearvalue(2)" class="clear-value" *ngIf="optioncontainer.value != undefined">
    cancel
  </mat-icon>
</mat-form-field>

<mat-form-field appearance="{{ appearance }}" class="{{ class }}" *ngIf="isValidation == true && isapisearch == true">
  <mat-label>
    {{ lable }}
    <span *ngIf="isRequired && lable != undefined && lable != null" class="required_symbol">
      *
    </span>
  </mat-label>
  <input name="{{ lable }}" style="display: none;" [formControl]="formControlNames" />
  <mat-select  *ngIf="!iscomparewith" placeholder="{{ Selectplaceholder ? Selectplaceholder : '' }}" #optioncontainer multiple="{{ ismultiple }}" (openedChange)="clearInputValu($event); resetMultiSelect()"
     msInfiniteScroll (infiniteScroll)="onScroll()" [complete]="iscomplete" #Selector
    [formControl]="apiselect" (selectionChange)="
      ifSelectionChange ? getSelectionChangeValue($event.value) : ''
    ">
    <form *ngIf="issearch" [formGroup]="searchForm" class="mat-filter"
      [ngStyle]="{ 'background-color': color ? color : 'white' }">
      <div class="d-flex">
        <input #filter class="mat-filter-input" matInput placeholder="{{ placeholder ? placeholder : 'Search...' }}"
          formControlName="value" />
        <mat-icon (click)="Reload()" *ngIf="!localSpinner && filter.value == '' && isapisearch == true">
          autorenew
        </mat-icon>
        <mat-icon (click)="clearInput()" *ngIf="filter.value != '' && !localSpinner">
          cancel
        </mat-icon>
        <mat-spinner *ngIf="localSpinner" class="spinner" diameter="16"></mat-spinner>
      </div>
      <div *ngIf="noResults" class="noResultsMessage">
        {{ noResultsMessage ? noResultsMessage : 'No results...' }}
      </div>
    </form>
    <mat-option (onSelectionChange)="ifonSelectionChange ? getOnSelectionChangevalue(item[textFieldValue]) : ''"
      (click)="ifclickevent ? getclickedvalue(item[textFieldValue]) : 
          !ifclickevent && !ifonSelectionChange && !ifSelectionChange && ifnoevent ? Setselectedvalue(item[textFieldValue]) : ''"
      *ngFor="let item of array" [value]="item[textFieldValue]">
      {{ item[textField] }}
    </mat-option>
  </mat-select>

  <mat-select *ngIf="iscomparewith" [compareWith]="objectComparisonFunctions" placeholder="{{ Selectplaceholder ? Selectplaceholder : '' }}" #optioncontainer multiple="{{ ismultiple }}" (openedChange)="clearInputValu($event); resetMultiSelect()"
   msInfiniteScroll (infiniteScroll)="onScroll()" [complete]="iscomplete" #Selector
  [formControl]="apiselect" (selectionChange)="
    ifSelectionChange ? getSelectionChangeValue($event.value) : ''
  ">
  <form *ngIf="issearch" [formGroup]="searchForm" class="mat-filter"
    [ngStyle]="{ 'background-color': color ? color : 'white' }">
    <div class="d-flex">
      <input #filter class="mat-filter-input" matInput placeholder="{{ placeholder ? placeholder : 'Search...' }}"
        formControlName="value" />
      <mat-icon (click)="Reload()" *ngIf="!localSpinner && filter.value == '' && isapisearch == true">
        autorenew
      </mat-icon>
      <mat-icon (click)="clearInput()" *ngIf="filter.value != '' && !localSpinner">
        cancel
      </mat-icon>
      <mat-spinner *ngIf="localSpinner" class="spinner" diameter="16"></mat-spinner>
    </div>
    <div *ngIf="noResults" class="noResultsMessage">
      {{ noResultsMessage ? noResultsMessage : 'No results...' }}
    </div>
  </form>
  <mat-option (onSelectionChange)="ifonSelectionChange ? getOnSelectionChangevalue(item[textFieldValue]) : ''"
    (click)="ifclickevent ? getclickedvalue(item[textFieldValue]) : 
        !ifclickevent && !ifonSelectionChange && !ifSelectionChange && ifnoevent ? Setselectedvalue(item[textFieldValue]) : ''"
    *ngFor="let item of array" [value]="item[textFieldValue]">
    {{ item[textField] }}
  </mat-option>
</mat-select>

  <span matSuffix *ngIf="
      (formControlNames.hasError('required') && formControlNames.touched) ||
      (formControlNames.hasError('required') && apiselect.touched)
    " matTooltip="{{ toolTips }}" [matTooltipDisabled]="!formControlNames.invalid">
    <mat-icon color="warn">info</mat-icon>
  </span>
</mat-form-field>

<div class="chiplists" *ngIf="isShowChip">
  <div class="fs-18 chip-header">
    <mat-lable>
      <b>{{ lable }} list</b>
    </mat-lable>
    <mat-icon class="float-right" (click)="closeChip()">cancel</mat-icon>
  </div>
  <mat-chip-list>
    <ng-container *ngFor="let option of selectedOptions; let i = index">
      <mat-chip class="d-flex-im my-custom-chip">
        {{ option[textField] }}
        <mat-icon (click)="removeChip(option, i)">cancel</mat-icon>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>

<div *ngIf="selectedOptions.length > 0 && ismultiple == false">
  <mat-chip-list>
    <ng-container *ngFor="let option of selectedOptions; let i = index">
      <mat-chip *ngIf="i < 1" class="d-flex-im my-custom-chip">
        {{ option[textField] }}
        <mat-icon (click)="removeChip(option, i)">cancel</mat-icon>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>

<div class="clip-flex my-chip-list" *ngIf="selectedOptions.length > 0 && ismultiple == true">
  <ng-container *ngFor="let option of selectedOptions; let i = index">
    <div *ngIf="i < 1" class="d-flex-im my-custom-chip">
      {{
      selectedOptions.length > 1
      ? (option[textField] | slice: 0:10)
      : option[textField]
      }}
      <span *ngIf="option[textField].length > 11">...</span>
      <mat-icon (click)="removeChip(option, i)">cancel</mat-icon>
    </div>
  </ng-container>
  <span (click)="showChip()" *ngIf="selectedOptions.length > 1">
    {{ selectedOptions.length - 1 }}+ More
  </span>
</div>