import { IMenuItem } from "./navigation.model";

export let NeolineMenu: IMenuItem[] = [
  {
    module: ["home"],
    name: "Dashboard",
    type: "link",
    state: "home",
  },

  // {
  //   module: ["Change Password"],
  //   name: "Change Password",
  //   type: "link",
  //   state: "",
  // },

  {
    module: ["BANKING Legal Opinion", "BANKING Modt", "BANKING Addt Modt", "BANKING Receipt", "BANKING MadeOver", "BANKING Yearly Enc", "BANKING Others"],
    name: "Banking",
    type: "dropDown",
    state: "",
    icon: "keyboard_arrow_down",

    sub: [
      {
        name: "Legal Opinion",
        type: "link",
        module: ["BANKING Legal Opinion"],
        state: "legal-opinion",
      },
      {
        name: "MODT",
        type: "link",
        module: ["BANKING Modt"],
        state: "banking-modt",
      },
      {
        name: "Additonal MODT",
        type: "link",
        module: ["BANKING Addt Modt"],
        state: "banking-add-modt",
      },
      {
        name: "Receipt",
        type: "link",
        module: ["BANKING Receipt"],
        state: "receipt",
      },
      {
        name: "Madeover",
        type: "link",
        module: ["BANKING MadeOver"],
        state: "madeover",
      },

      {
        name: "Yearly Encumbrance Certificate",
        type: "link",
        module: ["BANKING Yearly Enc"],
        state: "yearly-encumbrance-certificate",
      },

      {
        name: "Others",
        type: "link",
        module: ["BANKING Others"],
        state: "banking-others",
      },
    ],
  },
  {
    module: ["INDIVIDUAL Legal Opinion", "INDIVIDUAL Modt", "INDIVIDUAL Receipt", "INDIVIDUAL Doc Req", "INDIVIDUAL Legal Consultation", "INDIVIDUAL Project"],
    name: "Individual",
    type: "dropDown",
    state: "",
    icon: "keyboard_arrow_down",

    sub: [
      {
        name: "Legal Opinion",
        type: "link",
        module: ["INDIVIDUAL Legal Opinion"],
        state: "individual-legal-opinion",
      },
      {
        name: "MODT",
        type: "link",
        module: ["INDIVIDUAL Modt"],
        state: "individual-modt",
      },

      {
        name: "Receipt",
        type: "link",
        module: ["INDIVIDUAL Receipt"],
        state: "individual-receipt",
      },
      {
        name: "Documentation Requirements",
        type: "link",
        module: ["INDIVIDUAL Doc Req"],
        state: "documentation-requirements",
      },
      {
        name: "Legal Consultation",
        type: "link",
        module: ["INDIVIDUAL Legal Consultation"],
        state: "individual-legal-consult",
      },

      {
        name: "Projects",
        type: "link",
        module: ["INDIVIDUAL Project"],
        state: "individual-project",
      },
    ],
  },

  {
    module: ["NBFC Modt", "NBFC Addt Modt", "NBFC Yearly Enc", "NBFC Legal Opinion", "NBFC Receipt", "NBFC Others", "NBFC Madeover"],
    name: "NBFC",
    type: "dropDown",
    state: "",
    icon: "keyboard_arrow_down",

    sub: [
      {
        name: "Legal Opinion",
        type: "link",
        module: ["NBFC Legal Opinion"],
        state: "nbfc-legal-opinion",
      },
      {
        name: "MODT",
        type: "link",
        module: ["NBFC Modt"],
        state: "nbfc-modt",
      },
      {
        name: "Additonal MODT",
        type: "link",
        module: ["NBFC Addt Modt"],
        state: "nbfc-additional-modt",
      },
      {
        name: "Receipt",
        type: "link",
        module: ["NBFC Receipt"],
        state: "nbfc-receipt",
      },
      {
        name: "Madeover",
        type: "link",
        module: ["NBFC Madeover"],
        state: "nbfc-madeover",
      },

      {
        name: "Yearly Encumbrance Certificate",
        type: "link",
        module: ["NBFC Yearly Enc"],
        state: "nbfc-yearly-encumbrance-certificate",
      },

      {
        name: "Others",
        type: "link",
        module: ["NBFC Others"],
        state: "nbfc-others",
      },
    ],
  },

  {
    module: ["employee-task"],
    name: "Employee Task",
    type: "link",
    state: "employee-task",
  },

  {
    module: ["word form management"],
    name: "Word Form Management",
    type: "link",
    state: "word-form-management",
  },

  {
    module: ["payment transaction"],
    name: "Payment Transaction",
    type: "link",
    state: "payment-transaction",
  },

  {
    module: [""],
    name: "Reports",
    type: "dropDown",
    state: "",
    sub: [
      {
        name: "Banking Report",
        type: "link",
        module: [""],
        state: "banking-reports",
      },
      {
        name: "Individual Report",
        type: "link",
        module: [""],
        state: "individual-report",
      },

      {
        name: "NBFC Report",
        type: "link",
        module: [""],
        state: "nbfc-report",
      },
      {
        name: "Payment Transaction Report",
        type: "link",
        module: [""],
        state: "payment-transaction-report",
      },
    ],
  },

  {
    module: ["Employee", "Activity Log", "Branch Name", "Bank Name", "Designation", "Handling Charges", "NBFC Authority"
      , "Roles", "Roles and Permissions", "Users"],
    name: "Settings",
    type: "link",
    state: "settings/employee",
    sub: [
      {
        name: "Settings",
        type: "link",
        module: ["Employee"],
        state: "settings/employee",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Activity Log"],
        state: "settings/activity-log",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Branch Name"],
        state: "settings/bank-details",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Bank Name"],
        state: "settings/bank-details",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Change Password"],
        state: "settings/change-password",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Designation"],
        state: "settings/designation",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Handling Charges"],
        state: "settings/handling-charges",
      },
      {
        name: "Settings",
        type: "link",
        module: ["NBFC Authority"],
        state: "settings/nbfc-authority",
      },

      {
        name: "Settings",
        type: "link",
        module: ["Roles"],
        state: "settings/roles",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Roles and Permissions"],
        state: "settings/roles-permissions",
      },
      {
        name: "Settings",
        type: "link",
        module: ["Users"],
        state: "settings/users",
      },
    ],
  },

  // {
  //   module: ["reports"],
  //   name: "Reports",
  //   type: "link",
  //   state: "reports",
  // },
];

export let NeolineEmployeeMenu: IMenuItem[] = [
  {
    module: ["home"],
    name: "Task Manager",
    type: "link",
    state: "employee/task-manager",
  },
];

export let NeolineQueryMenu: IMenuItem[] = [
  {
    module: ["dashboard"],
    name: "dashboard",
    type: "link",
    state: "quary-employee/dashboard",
  },
];
export let NeolineQueryHeadMenu: IMenuItem[] = [
  {
    module: ["dashboard"],
    name: "dashboard",
    type: "link",
    state: "quary-head/dashboard",
  },
  {
    module: ["banking/legal-opinion"],
    name: "Legal Opinion",
    type: "link",
    state: "query-head/banking/legal-opinion",
  },
  {
    module: ["banking/modt"],
    name: "MODT",
    type: "link",
    state: "query-head/banking/modt",
  },
  {
    module: ["banking/modt"],
    name: "MODT",
    type: "link",
    state: "query-head/banking/modt",
  },
  {
    module: ["individual/legal-opinion"],
    name: "Legal Opinion",
    type: "link",
    state: "query-head/individual/legal-opinion",
  },
];

export let NeolineNBFCMenu: IMenuItem[] = [
  // {
  //   module: ["home"],
  //   name: "Dashboard",
  //   type: "link",
  //   state: "nbfc/home",
  //   icon: "dashboard",
  // },
  {
    module: ["legal-opinion-nbfc"],
    name: "Legal Opinion",
    type: "link",
    state: "nbfc/legal-opinion-nbfc",
  },
  {
    module: [""],
    name: "MODT",
    type: "link",
    state: "nbfc/modt",
  },
  {
    module: ["additional-modt"],
    name: "Additional MODT",
    type: "link",
    state: "nbfc/additional-modt",
  },
  {
    module: ["receipt"],
    name: "Receipt",
    type: "link",
    state: "nbfc/receipt",
  },
  {
    module: ["madeover"],
    name: "Madeover",
    type: "link",
    state: "nbfc/madeover",
  },
  {
    module: ["employee"],
    name: "Employee",
    type: "link",
    state: "nbfc/employee",
  },
  {
    module: ["yearly-encumbrance-certificate"],
    name: "Yearly Encumbrance Certificate",
    type: "link",
    state: "nbfc/yearly-encumbrance-certificate",
  },
  {
    module: ["others"],
    name: "Others",
    type: "link",
    state: "nbfc/others",
  },

  // {
  //   module: [""],
  //   name: "Legal Opinion",
  //   type: "link",
  //   state: "nbfc/employee-legal-opinion",
  // },
];

export let NeolineNBFCEmployeeMenu: IMenuItem[] = [
  // {
  //   module: ["home"],
  //   name: "Dashboard",
  //   type: "link",
  //   state: "nbfc-employee/home",
  //   icon: "dashboard",
  // },
  {
    module: ["employee-legal-opinion"],
    name: "Legal Opinion",
    type: "link",
    state: "nbfc-employee/employee-legal-opinion",
  },
  {
    module: ["employee-modt"],
    name: "MODT",
    type: "link",
    state: "nbfc-employee/employee-modt",
  },
  {
    module: ["employee-additional-modt"],
    name: "Additional MODT",
    type: "link",
    state: "nbfc-employee/employee-additional-modt",
  },
  {
    module: ["employee-receipt"],
    name: "Receipt",
    type: "link",
    state: "nbfc-employee/employee-receipt",
  },
  {
    module: ["employee-madeover"],
    name: "MadeOver",
    type: "link",
    state: "nbfc-employee/employee-madeover",
  },
  {
    module: ["employee-yearly-encumbrance-certificate"],
    name: "Yearly Encumbrance Certificate",
    type: "link",
    state: "nbfc-employee/employee-yearly-encumbrance-certificate",
  },
  {
    module: ["employee-others"],
    name: "Others",
    type: "link",
    state: "nbfc-employee/employee-others",
  },
];
