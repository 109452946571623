// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import { config } from "config";

export const environment = {
  production: true,
  apiURL: config.apiUrl,
  employeeapiURL: config.employeeapiURL,
  nbfcapiURL: config.nbfcapiURL,
  nbfcemployeeapiURL: config.nbfcemployeeapiURL,
  phpAPIURL: config.phpApiURL,
  firebaseConfig: {
      apiKey: "AIzaSyDdUiG4_XVmvAadVTNfuQRwLBjCla3L7A4",
      authDomain: "suzhiyam-app-a7d80.firebaseapp.com",
      projectId: "suzhiyam-app-a7d80",
      storageBucket: "suzhiyam-app-a7d80.appspot.com",
      messagingSenderId: "384852622447",
      appId: "1:384852622447:web:225d38a2c005b3bc4e619a",
      measurementId: "G-T4D5K3XCQR"    
  }
};
