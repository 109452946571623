import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from './user.service';
import { take, map } from 'rxjs/operators';
import { decodedToken } from '../helpers/token.helper';
import { PORTALTYPE } from '../helpers/enum.helper';
import { NBFCService } from './nbfc.service';
import { DataHeadService } from './data-head.service ';

@Injectable({
  providedIn: 'root'
})
export class DataHeadAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private venueService: DataHeadService,
    private userservice: UserService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    return this.venueService.isLoggedIn.pipe(take(1),
      map((isLoggedIn: boolean) => {
        let checkOTP = localStorage.getItem('ISOTPVERIFIED') ? true : false;
        let canLogin = isLoggedIn && checkOTP;
        let token = decodedToken();
        if(token.portal != PORTALTYPE.EMPLOYEE){
          this.userservice.logout();
        }
        else{
          if (!canLogin) {
            //   this.router.navigate(['/']); 
              this.venueService.logout();
              return false;
            }
        }
        return true;
      })
    );



  }
}
