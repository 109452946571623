import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refer-skeleton-loader',
  templateUrl: './refer-skeleton-loader.component.html',
  styleUrls: ['./refer-skeleton-loader.component.scss']
})
export class ReferSkeletonLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
