import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
    selector: '[appDot]'
  })
  export class dotDirective {

    constructor(private el: ElementRef) {
    }
    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent) {
      let current: string = this.el.nativeElement.value;
      if (event.key != "Enter") {
        if (event.key == "." || event.key == "e") {
          event.preventDefault();
          return;
        }
      }
      }
    }
  