import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { saveToken, destroyToken, hasToken, decodedToken, clearSessionData } from '../helpers/token.helper';
import { User, IUserAPI } from '../models';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { ApiAdapter } from './user/api.adapter.service';
import { get } from "lodash";
import { PORTALTYPE } from "../helpers/enum.helper";
import { HttpParams } from '@angular/common/http';
import { EmployeeApiService } from './employeeapi.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isScrollToTop = new Subject();
  portalType = '';

  // settings routing restriction

  public isInSettingsMenu = new Subject();

  // sos notification

  public arrayOfSosSub = new Subject();

  //nomovement notification
  public arrayOfNomovementSub = new ReplaySubject(1000);

  // patient details notification

  public arrayOfPatientSub = new ReplaySubject(1000);


  constructor(
    private apiService: EmployeeApiService,
    private router: Router,
    private adapter: ApiAdapter,
  ) {

    this.isAuthenticatedSubject.next(hasToken());
    this.currentUserSubject.next(this.getSessionUser());

  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  signin(formData): Observable<IUserAPI> {
    return this.apiService.post(`/api/adminEmployeeAuthentication/login`, formData).pipe(map(response => this.adapter.adapt(response)));
  }

  NextHearingUpdate(apiurl, formData): Observable<any> {
    return this.apiService.post(`${apiurl}`, formData);
  }

  otpVerify(formData): Observable<any> {
    return this.apiService.post('venue/getotp', formData);
  }

  loginwithotp(obj): Observable<IUserAPI>{
    return this.apiService.post('venue/otpVerify', obj).pipe(map(response => this.adapter.adapt(response)));
  }

  forgetpassword(formData): Observable<any> {
    let params = new HttpParams();
    for (let key in formData) {
      params = params.set(key, formData[key]);
    }
    return this.apiService.post('/api/adminEmployee/forgotPassword', params);
  }

  
  resetPswd(formData): Observable<any> {
    return this.apiService.post('/api/adminEmployee/resetPassword', formData);
  }


  setAuth(user: User) {
    // Save JWT sent from server in localstorage
    saveToken(user.token);
    window.localStorage.setItem('sesUsr', JSON.stringify(user));
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
  }

  purgeAuth() {
    // Remove JWT from localstorage
    destroyToken();
    clearSessionData();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);

  }


  sendResetLink(model: User): Observable<IUserAPI> {
    let formData = new FormData();
    formData.append('email', model.email);
    return this.apiService.post('hospital/forget', formData).pipe(map(response => this.adapter.adapt(response)));
  }


  chagePassword(model: User): Observable<IUserAPI> {
    let obj = {
      newPassword: model.newPassword,
      oldPassword: model.oldPassword
    }
    let params = new HttpParams();
    for (let key in obj) {
      params = params.set(key, obj[key]);
    }
    return this.apiService.post('user/password/changePassword', params);
  }

  getCurrentUser(): User {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }

  get isLoggedIn() {
    return this.isAuthenticatedSubject.asObservable();
  }

  logout() {
    this.getPortalType();
    this.purgeAuth();
    this.goToLogin();
  }

  getSessionUser(): User {
    return JSON.parse(window.localStorage.getItem('sesUsr'));
  }


  goToLogin() {
    if(this.portalType == PORTALTYPE.EMPLOYEE) {
      this.router.navigate(['/query-admin']);
    }
    else {
      this.router.navigate(['/query-admin']);
    }
  }



  getPortalType() {
    const token = decodedToken();
    this.portalType = get(token, 'portal') ?? '';
  }


  pushSosNotifies(sos) {
    this.arrayOfSosSub.next(sos)
  }

}
